import React, { useEffect, useState, useRef } from 'react';
import Draggable from 'react-draggable';

const commonParameters = require('./common_parameters');

const Dialog2 = ({ onDialog2Change, ...props }) => {
  //console.log("d2 start=" + props.selectedValue);

  const [selected, setSelected] = useState(props.selectedValue);
  useEffect(() => {
    setSelected(props.selectedValue);
  }, [props.selectedValue]);
  
  /** ラジオボタン切り替えイベント */
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => setSelected(event.target.value);

  const dialogRef = useRef(null);

  const RadioButtons = () => {
    return (
      <div className='px-3 pb-2'>
        {commonParameters.zone_table2.map(zone => (
          <div key={zone.type}>
            <input 
              type="radio" 
              name="zone" 
              value={zone.type} 
              checked={selected == zone.type} 
              onChange={changeValue}
              />
            <label className='ms-1'>{zone.name}</label>
          </div>
        ))}
      </div>
    );
  };
  
  // 選択ボタンクリック時
  const handleCalDialog = () => {
    //console.log("cal=" + selected);
    onDialog2Change(selected);
    dialogRef.current.close();
    //dialogRef.current.showModal();
  };

  // キャンセルボタンクリック時
  const handleCloseDialog = () => {
    dialogRef.current.close();
  };

  return (
    <Draggable defaultPosition={{x: 0, y: 0}}
      cancel='p'>
      <dialog className='map-dialog p-0 border-info border-1' id='map-dialog2' ref={dialogRef}>
      {/*console.log("d2=render=" + selected)*/}
        <h6 className='bg-info text-white p-2 mb-1'>ゾーンタイプを選択してください</h6>
        <RadioButtons />
        <div className='text-center mb-2'>
          <button className="px-3 me-2" id="openButton" onClick={handleCalDialog}>選択</button>
          <button className="" id="closeButton" onClick={handleCloseDialog} >キャンセル</button>
        </div>
      </dialog>
      </Draggable>
    );
};

export default Dialog2;