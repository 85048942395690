import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import Draggable from 'react-draggable';
import Sidebar from './Sidebar';
import Dialog2 from './Dialog2';
import Dialog3 from './Dialog3';
import Dialog4 from './Dialog4';
import Dialog5 from './Dialog5';

import Fetchparam from './Fetchparam';
import Fetchposts from './Fetchposts';
import Fetchresults from './Fetchresults';

import Header from "../components/Header";
import Loading from "../components/Loading";

const commonParameters = require('./common_parameters');

// MapBox Access Token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default function Station() {
    // デフォルト設定
    const mapContainer = useRef(null);
    const map = useRef(null);
   
    const [lng, setLng] = useState(138.9152124590662);
    const [lat, setLat] = useState(35.19399989531848);
    const [zoom, setZoom] = useState(15.5);
    //const [cityid, setCityid ] = useState(1038);

    const [guide, setGuide ] = useState("アクション１ ゾーン自動設定");
    const [btnname, setBtnname ] = useState("自動設定");
    const [actioninfo, setActioninfo ] = useState("赤丸●は新駅予定地で、黒い枠は計画地です。新駅を中心にゾーンを自動設定します。「自動設定」ボタンを押してください。");
    const [action, setAction ] = useState("1");
    const inputRef = useRef(null);

    const [zonedata, setZonedata] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");

    const [defaultparam, setDefaultparam] = useState([]);    // API param output (a)
    const [defaultresult, setDefaultresult] = useState([]);     // API result output (b)

    const [executeparam, setExecuteparam] = useState([]);       // API execute input parameters (a)
    const [executeresult, setExecuteresult] = useState([]);     // API execute returns (b)

    const [blockID, setBlockID] = useState("");
    const [zoneID, setZoneID] = useState("");
    const [parcel, setParcel] = useState("");

    const prob_dict = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    const [wasLoaded, setWasLoaded] = useState(false);

    // マップの初期化
    useEffect(() => {

        initExecute();

        if (map.current) return; // initialize map only once
        //console.log("initialize map");
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            bearing: -12,
            pitch: 50,
            center: [lng, lat],
            zoom: zoom
        });

        map.current.addControl(new MapboxLanguage({
            defaultLanguage: 'ja'
        }));

        const zoomControl = new mapboxgl.NavigationControl();
        map.current.addControl(zoomControl, 'top-left');

        // アトリビューションコントロールの作成
        const attributionControl = new mapboxgl.AttributionControl({
            compact: true // オプション: コンパクトな表示スタイルに設定
        });
        // アトリビューションコントロールの追加
        map.current.addControl(attributionControl, 'bottom-left');
    }, []);

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('load', function () {
            //console.log("map=" + map.current);
            //カーソルスタイルをデフォルトへ
            map.current.getCanvas().style.cursor = "";

            // 行政境界
            map.current.addSource(commonParameters.SRC_DIVISION, {
                'type': 'geojson',
                data: './data/1038/susono.geojson'
            });
            map.current.addLayer({
                id: commonParameters.DIVISION_ID,
                type: "line",
                source: commonParameters.SRC_DIVISION,
                paint: {
                    "line-width": 3,
                    "line-color": "#606060",
                    "line-opacity": 1.0,
                }
            });

            // redline
            map.current.addSource(commonParameters.SRC_REDLINE, {
                'type': 'geojson',
                data: './data/1038/redline.geojson'
            });
            map.current.addLayer({
                id: commonParameters.REDLINE_ID,
                type: "line",
                source: commonParameters.SRC_REDLINE,
                paint: {
                    "line-width": 2,
                    "line-color": "#606060",
                    "line-opacity": 1.0,
                }
            });

            // parcel_withdata 未使用？
            /*map.current.addSource(commonParameters.SRC_PARCEL_WITHDATA, {
                type: "geojson",
                data: './data/1038/parcel_withdata.geojson'
            });
            map.current.addLayer({
                id: commonParameters.PARCEL_WITHDATA_ID,
                type: "fill",
                source: commonParameters.SRC_PARCEL_WITHDATA,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    'fill-outline-color': '#000',
                    'fill-color': '#888',
                    'fill-opacity': 1.0
                }
            });*/
            
            // parcel_withdata and height
            map.current.addSource(commonParameters.SRC_PARCEL_WITHDATA_2, {
                type: "geojson",
                data: './data/1038/parcel_withdata_2.geojson'
            });
            map.current.addLayer({
                id: commonParameters.PARCEL_WITHDATA_2_ID,
                type: "fill",
                source: commonParameters.SRC_PARCEL_WITHDATA_2,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    //'fill-outline-color': '#000',
                    //'fill-color': '#888',
                    //'fill-opacity': 1.0
                    'fill-color': [
                        'match',
                        ['get', 'future_lu'],
                        1, '#03AF7A',
                        2, '#F6AA00',
                        3, '#FF4B00',
                        4, '#4DC4FF',
                        5, '#005AFF',
                        '#888'
                    ],
                    'fill-outline-color': [
                        'match',
                        ['get', 'future_lu'],
                        1, '#03AF7A',
                        2, '#F6AA00',
                        3, '#FF4B00',
                        4, '#4DC4FF',
                        5, '#005AFF',
                        '#000'
                    ],
                    'fill-opacity': 1.0
                }
            });   
            map.current.addLayer({
                id: commonParameters.BUILDINGS_3D_DATA_ID,
                type: "fill-extrusion",
                source: commonParameters.SRC_PARCEL_WITHDATA_2,
                'minzoom': 15,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': ['*', ['get', 'height_build'], 2.0],
                    'fill-extrusion-base': 0,
                    'fill-extrusion-opacity': 1.0
                }
            });   
            /*map.current.addLayer({
                id: commonParameters.DATA_ID,
                type: "fill",
                source: commonParameters.SRC_PARCEL_WITHDATA_2,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    'fill-outline-color': '#000',
                    'fill-color': '#000',
                    'fill-opacity': 0.0
                }
            });*/
        
            // building
            map.current.addSource(commonParameters.SRC_BUILDINGS, {
                type: "geojson",
                data: './data/1038/building_new.geojson'
            });
            map.current.addLayer({
                id: commonParameters.BUILDINGS_ID,
                type: "fill",
                source: commonParameters.SRC_BUILDINGS,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    'fill-outline-color': '#000',
                    'fill-color': '#888',
                    'fill-opacity': 0.5
                }
            });

            // parcel_withdata and height
            map.current.addLayer({
                id: commonParameters.BUILDINGS_3D_ID,
                type: "fill-extrusion",
                source: commonParameters.SRC_BUILDINGS,
                'minzoom': 15,
                'layout': {
                    'visibility': 'none',
                },
                'paint': {
                    //'fill-extrusion-color': '#aaa',
                    'fill-extrusion-color': [
                        'match',
                        ['get', 'future_lu'],
                        1, '#03AF7A',
                        2, '#F6AA00',
                        3, '#FF4B00',
                        4, '#4DC4FF',
                        5, '#005AFF',
                        '#aaa'
                    ],
                    'fill-extrusion-height': ['*', ['get', 'height'], 2.0],
                    //'fill-extrusion-height': ['get', 'height'],
                    'fill-extrusion-base': 0,
                    'fill-extrusion-opacity': 1.0
                }
            });
    
            // block
            map.current.addSource(commonParameters.SRC_BLOCK, {
                type: "geojson",
                data: './data/1038/block.geojson'
            });   
            map.current.addLayer({
                id: commonParameters.BLOCK_ID,
                type: "fill",
                source: commonParameters.SRC_BLOCK,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    'fill-outline-color': '#000',
                    'fill-color': '#888',
                    'fill-opacity': 0.4
                }
            });

            // road_boundary
            map.current.addSource(commonParameters.SRC_ROAD_BOUNDARY, {
                type: "geojson",
                data: './data/1038/road_boundary.geojson'
            });   
            map.current.addLayer({
                id: commonParameters.ROAD_BOUNDARY_ID,
                type: "line",
                source: commonParameters.SRC_ROAD_BOUNDARY,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    "line-width": 1,
                    "line-color": "#606060",
                    "line-opacity": 1.0,
                }
            });

            // road_mid
            //2023.5.30 非表示
            /*map.current.addSource(commonParameters.SRC_ROAD_MID, {
                type: "geojson",
                data: './data/1038/road_mid.geojson'
            });   
            map.current.addLayer({
                id: commonParameters.ROAD_MID_ID,
                type: "line",
                source: commonParameters.SRC_ROAD_MID,
                'layout': {
                    'visibility': 'none',
                },
                paint: {
                    "line-width": 1,
                    //"line-color": "#606060",
                    "line-color": "#0000ff",
                    "line-opacity": 1.0,
                }
            });*/

            // 新駅ポイント
            map.current.addSource(commonParameters.SRC_NEW_STATION, {
                'type': 'geojson',
                data: './data/1038/new_station.geojson'
            });
            map.current.addLayer({
                id: commonParameters.NEW_STATION_ID,
                type: 'circle',
                source: commonParameters.SRC_NEW_STATION,
                paint: {
                    'circle-color': '#ff0000',
                    'circle-radius': 8,
                    'circle-opacity': 0.8
                }
            });
            
        });      
      
        // コンポーネントがアンマウントされたときにマップを破棄する
        return () => map.current.remove();
        //return;
    }, []);

    useEffect(() => {
        map.current.on('click', commonParameters.NEW_STATION_ID, (e) => {
            new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML("新駅予定地")
            .addTo(map.current);
        });

        map.current.on('click', (e) => {
            const action = inputRef.current.value;
            //console.log('actionの値:',action);
            // クリックイベントが発生したときに実行される処理
            const features = map.current.queryRenderedFeatures(e.point);
            // レイヤーや特定の属性に基づいて処理を行うなど、必要な操作を行います。
            //console.log(features);
            if (features.length <= 0) {
                return;
            }
            if(action==2){
                //const feature = features.filter((feat) => feat.layer.id.includes('area_style'));
                //setZoneID(feature[0]["properties"]["no"]);
                //var type = feature[0]["properties"]["type"];

                const feature = features.filter((feat) => feat.layer.id.includes('Layer_zone_area'));
                setZoneID(feature[0]["properties"]["FID_"]);

                var type = feature[0]["properties"]["zone_type"];
                setSelectedValue(type);     // propsへ               
                //console.log(feature);

                // ダイアログを表示  ゾーンタイプ選択     
                const MyDialog2 = document.getElementById('map-dialog2');
                if(MyDialog2.open) {
                    MyDialog2.close();
                }
                MyDialog2.showModal(); // Show the dialog

            }else if(action==3){
                const feature = features.filter((feat) => feat.layer.id.includes('layer_block'));
                if (feature.length <= 0) {
                    return;
                }
                var block_id = feature[0]["properties"]["block_id"];
                setBlockID(block_id);
                //console.log(block_id);

                // ダイアログを表示  土地利用の割合選択          
                const MyDialog3 = document.getElementById('map-dialog3');
                if(MyDialog3.open) {
                    MyDialog3.close();
                }
                MyDialog3.showModal(); // Show the dialog

            }else if(action==4){
                const feature = features.filter((feat) => feat.layer.id.includes('layer_parcel'));
                //console.log(feature);
                if (feature.length <= 0) {
                    return;
                }

                //var properties = feature[0]["properties"];
                var parcel_id = feature[0]["properties"]["parcel_id"];
                //console.log("parcel=" + parcel_id);
                setParcel(parcel_id);

                // ダイアログを表示  建ぺい率、容積率選択          
                const MyDialog4 = document.getElementById('map-dialog4');
                if(MyDialog4.open) {
                    MyDialog4.close();
                }
                MyDialog4.showModal(); // Show the dialog

            }else if(action==5){
                const feature = features.filter((feat) => feat.layer.id.includes('layer_parcel'));
                //console.log(feature);
                if (feature.length <= 0) {
                    return;
                }

                //var properties = feature[0]["properties"];
                var parcel_id = feature[0]["properties"]["parcel_id"];
                //console.log("parcel=" + parcel_id);
                setParcel(parcel_id);

                // ダイアログを表示  建ぺい率、容積率選択          
                const MyDialog5 = document.getElementById('map-dialog5');
                if(MyDialog5.open) {
                    MyDialog5.close();
                }
                MyDialog5.showModal(); // Show the dialog

            }
        });
                 
        //console.log("m=8");
        // コンポーネントがアンマウントされたときにマップを破棄する
        //return () => map.current.remove();
        return;
    }, []);

    const getAction = () => {                
        //console.log("getAction =" + action);
        //console.log(defaultparam.zone_mapping);
        //console.log(executeresult);
        // アクションボタンクリック時
        if(action==0){  // デフォルト
            setGuide("アクション１ ゾーン自動設定");
            setBtnname("自動設定");
            setActioninfo("赤丸●は新駅予定地で、黒い枠は計画地です。新駅を中心にゾーンを自動設定します。「自動設定」ボタンを押してください。");
            setAction(1);

            map.current.setLayoutProperty(commonParameters.BLOCK_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.ROAD_BOUNDARY_ID, 'visibility', 'none');
            //2023.5.30 非表示map.current.setLayoutProperty(commonParameters.ROAD_MID_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.PARCEL_WITHDATA_2_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.BUILDINGS_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.BUILDINGS_3D_ID, 'visibility', 'none');
                   
        }else if(action==1){
            setGuide("アクション２ 街区の自動作成");
            setBtnname("自動作成");
            setActioninfo("ゾーンをクリックしてタイプを選択してください。終わったら「自動作成」ボタンを押すと街区を自動作成します。");
            setAction(2);
            
            // ゾーンの配色、ラベル
            zone_display();
        
        }else if(action==2){
            setGuide("アクション３ ロット(区画)の自動配置");
            setBtnname("自動配置");
            setActioninfo("街区を作成しました！ゾーンをクリックして土地利用の割合を入力してください。終わったら「自動配置」ボタンを押します。");
            setAction(3);

            map.current.setLayoutProperty(commonParameters.BLOCK_ID, 'visibility', 'visible');
            map.current.setLayoutProperty(commonParameters.ROAD_BOUNDARY_ID, 'visibility', 'visible');
            //2023.5.30 非表示map.current.setLayoutProperty(commonParameters.ROAD_MID_ID, 'visibility', 'visible');

            // 2023.5.29
            //console.log("api start sidebar reflash");
            var params = executeparam;
            getExecute(params);
            //console.log("api send");

        }else if(action==3){
            setGuide("アクション４ ３次元建物の生成");
            setBtnname("３次元表示");
            setActioninfo("ロット（区画）を自動配置しました。次に、ロット（区画）をクリックして建ぺい率と容積率を入力してください。終わったら「３次元表示」ボタンを押します。");
            setAction(4);

            // 2023.5.29 ゾーンの背景色を削除
            map.current.setPaintProperty('Layer_zone_area', 'fill-color', 'rgba(0, 0, 0, 0)'); // 透明な色
            map.current.setPaintProperty('Layer_zone_area', 'fill-outline-color', '#606060'); // #606060
            map.current.setPaintProperty('Layer_zone_area', 'fill-opacity', 1.0); // 

            parcel_display();

            map.current.setLayoutProperty(commonParameters.PARCEL_WITHDATA_2_ID, 'visibility', 'visible');
            map.current.setLayoutProperty(commonParameters.BUILDINGS_ID, 'visibility', 'visible');

        }else if(action==4){
            setGuide("");
            setActioninfo("３次元建物の生成をしました（※建物の高さは×4ｍで表示しています）。ロット（区画）をクリックすると詳細情報が表示されます。「終了」ボタンを押すと終了します。");
            setBtnname("終了");
            setAction(5);

            buildings_display();

            map.current.setLayoutProperty(commonParameters.BUILDINGS_3D_ID, 'visibility', 'visible');
        }else if(action==5){
            setGuide("アクション１ ゾーン自動設定");
            setBtnname("自動設定");
            setActioninfo("赤丸●は新駅予定地で、黒い枠は計画地です。新駅を中心にゾーンを自動設定します。「自動設定」ボタンを押してください。");
            setAction(1);

            map.current.setLayoutProperty(commonParameters.BLOCK_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.ROAD_BOUNDARY_ID, 'visibility', 'none');
            //2023.5.30 非表示map.current.setLayoutProperty(commonParameters.ROAD_MID_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.PARCEL_WITHDATA_2_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.BUILDINGS_ID, 'visibility', 'none');
            map.current.setLayoutProperty(commonParameters.BUILDINGS_3D_ID, 'visibility', 'none');

            if (map.current.getLayer('Layer_zone_area')) {
                map.current.removeLayer('Layer_zone_area');
            }
            
            if (map.current.getLayer('Layer_zone_label')) {
                map.current.removeLayer('Layer_zone_label');
            }

            if (map.current.getSource('zone_area')) {
                map.current.removeSource('zone_area');
            }
                
            initExecute();
        }else if(action==6){
            setGuide("アクション２ 予定道路の計算結果 ");
            setBtnname("終了");
            setActioninfo("");
        }
    }
    
    // ゾーンタイプ 選択時
    const handleDialog2Change = (selected) => {
        //console.log("m2=" + selected);  // 変更するタイプ
        //console.log("zid=" + zoneID);   // FID_
        //console.log("selval=" + selectedValue);   // 現在のタイプ
      
        // 1. zonedata UPD
        //console.log(zonedata);
        //zonedata.features[zoneID].properties.zone_type = selectedValue.toString();
        //zonedata.features[zoneID].properties.zone_name = name;
        var name = commonParameters.zone_table.find(item => item.type == selected)?.name;
        //console.log("name=" + name);

        const updatedData = zonedata.features.map(item => {
            if (item.properties.FID_ == zoneID) {
              return {
                ...item,
                properties: {
                  ...item.properties,
                  zone_type: selected,
                  zone_name: name
                }
              };
            } else {
              return item;
            }
          });
        
        var zone_data = {
            "type": "FeatureCollection",
            "features": updatedData
          };
        setZonedata(zone_data);
        //console.log(zone_data);

        // 背景色、名前の変更
        map.current.getSource('zone_area').setData(zone_data);
        
        //map.current.setPaintProperty('area_style_' + zoneID, 'fill-color', color);
        //map.current.setPaintProperty('area_style_' + zoneID, 'fill-outline-color', color);
        //map.current.setLayoutProperty('label_style_' + zoneID, 'text-field', name);

        // 2. API execute input parameters UPD

        var params = executeparam;
        //console.log(params);

        params.zone_mapping[selectedValue] = name;
        setExecuteparam(params);
        //console.log(params.zone_mapping);

        // 変更の度にAPI必要なし 
        //console.log("api start sidebar reflash");
        //getExecute(params);
        //console.log("api send");

        return;
    };

    // 土地利用の割合 選択時
    const handleDialog3Change = (block_id, zone_type, inputValue1, inputValue2, inputValue3, inputValue4, inputValue5) => {
        //console.log("m3=" + block_id);
        //console.log("m3=" + lu_code);
        //console.log("m3=" + inputValue1);
        //console.log("m3=" + inputValue2);
        //console.log("m3=" + inputValue3);
        //console.log("m3=" + inputValue4);
        //console.log("m3=" + inputValue5);

        var params = executeparam;
        //console.log(params);
        params.prob_dict[zone_type] = [Number(inputValue1/100), Number(inputValue2/100), Number(inputValue3/100), Number(inputValue4/100), Number(inputValue5/100)]
        //console.log(params);
       
        setExecuteparam(params);

        //console.log("api start sidebar reflash");
        getExecute(params);
        //console.log("api send");

        return;
    };
    // 建ぺい率、容積率 選択時
    const handleDialog4Change = (block_id, FID, inputValue1, inputValue2) => {
        //console.log("m3=" + block_id);
        //console.log("m3=" + FID);
        //console.log("m3=" + inputValue1);
        //console.log("m3=" + inputValue2);

        var params = executeparam;
        if (!params['bcr_far']) {
            params['bcr_far'] = [];
          }
          
          let index = params['bcr_far'].findIndex(item => item.FID === FID);
          
          if (index !== -1) {
            params['bcr_far'][index] = {"FID": FID, "BCR": Number(inputValue1/100), "FAR": Number(inputValue2/100)};
          } else {
            params['bcr_far'].push({"FID": FID, "BCR": Number(inputValue1/100), "FAR": Number(inputValue2/100)});
          }
                         
        setExecuteparam(params);

        //console.log("api start sidebar reflash");

        getExecute(params);

        //console.log("api send");

        return;
    };
    
    async function initExecute() {
        //console.log("initExecute");
        // API Station Default Param
        const param = await Fetchparam();
        //console.log(param);

        setDefaultparam(param);
        setExecuteparam(param);

        // API Station Result
        const data = await Fetchposts();
        //console.log(data);

        setDefaultresult(data);
        setExecuteresult(data);

        // init_zone ゾーン位置情報 + タイプ
        for (var ii=0; ii<commonParameters.zone_features.length; ii++) {

            const index = commonParameters.zone_features[ii].properties.FID_ - 1;

            const entries = Object.entries(param.zone_mapping);
            const [type, name] = entries[index];

            commonParameters.zone_features[ii].properties.zone_type = type;
            commonParameters.zone_features[ii].properties.zone_name = name;
            //commonParameters.zone_features[ii].properties.zone_no = index;
        }
        var zone_data = {
            "type": "FeatureCollection",
            "features": commonParameters.zone_features
          };
        setZonedata(zone_data);

        setIsLoading(false);
    }

    async function getExecute(params) {
        setIsLoading(true);
        // API Station Result
        const data = await Fetchresults(params);
        if(data){
            //console.log(data);
            setExecuteresult(data);
        }        
        setIsLoading(false);
    }

    function zone_display() {

        map.current.addSource('zone_area', {
            'type': 'geojson',
            'data': zonedata
        });

        // Add Color
        map.current.addLayer({
            'id': 'Layer_zone_area',
            "type": "fill",
            'source': 'zone_area',
            'layout': {},
            'paint': {
                'fill-color': [
                    'match',
                    ['get', 'zone_type'],
                    '1', 'BlueViolet',
                    '2', 'green',
                    '3', 'red',
                    '4', 'yellow',
                    '5', 'red',
                    '6', 'blue',
                    '7', 'aqua',
                    '8', 'MidnightBlue',
                    '9', 'OrangeRed',
                    '10', 'fuchsia',
                    '11', 'lime',
                    '12', 'maroon',
                    '13', 'yellow',
                    '#606060'
                ],
                'fill-outline-color': [
                    'match',
                    ['get', 'zone_type'],
                    '1', 'BlueViolet',
                    '2', 'green',
                    '3', 'red',
                    '4', 'yellow',
                    '5', 'red',
                    '6', 'blue',
                    '7', 'aqua',
                    '8', 'MidnightBlue',
                    '9', 'OrangeRed',
                    '10', 'fuchsia',
                    '11', 'lime',
                    '12', 'maroon',
                    '13', 'yellow',
                    '#606060'
                ],
                'fill-opacity': 0.4
            }
        });

        // Add Label
        map.current.addLayer({
            'id': 'Layer_zone_label',
            'type': 'symbol',
            'source': 'zone_area',
            'layout': {
                'text-field': [
                    'match',
                    ['get', 'zone_type'],
                    '1', '郷土資料館',
                    '2', 'スーパマーケット',
                    '3', '児童公園',
                    '4', '住宅',
                    '5', '公園',
                    '6', 'バスターミナル',
                    '7', '飲食店街',
                    '8', '老人ホーム、福祉施設',
                    '9', '運動場、幼児施設',
                    '10', 'ショッピングモール',
                    '11', '病院',
                    '12', '集合住宅',
                    '13', '住宅',
                    ''
                ],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0.0, 0.0],
                'text-anchor': 'center',
                "text-size": 11,
                "text-justify": 'auto'
            },
            'paint': {
                'text-color': 'black'
            }
        });
    }

    function parcel_display() {       
        //console.log("parcel_display");

        var URL = './data/1038/parcel_withdata_2.geojson';
        fetch(URL)
            .then((res) =>
                res.json())
            .then((response) => {

                var updatedData = response.features;
                for (var ii=0; ii<executeresult.parcel_new_station.length; ii++) {

                    var parcel_id = executeresult.parcel_new_station[ii].parcel_id;
                    var future_lu = executeresult.parcel_new_station[ii].future_lu;

                    updatedData = updatedData.map(item => {
                    if (item.properties.parcel_id == parcel_id) {
                      return {
                        ...item,
                        properties: {
                          ...item.properties,
                          future_lu: future_lu
                        }
                      };
                    } else {
                      return item;
                    }
                  });
                }
                var zone_data = {
                    "type": "FeatureCollection",
                    "features": updatedData
                };

                //console.log(zone_data);
                // 背景色の変更
                map.current.getSource(commonParameters.SRC_PARCEL_WITHDATA_2).setData(zone_data);
        });
    }

    function buildings_display() {       
        //console.log("buildings_display");

        var URL = './data/1038/building_new.geojson';
        fetch(URL)
            .then((res) =>
                res.json())
            .then((response) => {

                var updatedData = response.features;
                for (var ii=0; ii<executeresult.parcel_new_station.length; ii++) {

                    var parcel_id = executeresult.parcel_new_station[ii].parcel_id;
                    var future_lu = executeresult.parcel_new_station[ii].future_lu;
                    var floor = executeresult.parcel_new_station[ii].floor;

                    updatedData = updatedData.map(item => {
                    if (item.properties.parcel_id == parcel_id) {
                      return {
                        ...item,
                        properties: {
                          ...item.properties,
                          //future_lu: future_lu,
                          floor: floor,
                          height : floor * 4
                        }
                      };
                    } else {
                      return item;
                    }
                  });
                }

                var buildings_data = {
                    "type": "FeatureCollection",
                    "features": updatedData
                };

                //console.log(buildings_data);
                // ビルの高さ
                map.current.getSource(commonParameters.SRC_BUILDINGS).setData(buildings_data);
        });
    }
      
    // 凡例の表示
    var hanrei = getexceptions();
    function getexceptions() {
        const filteredZones = commonParameters.zone_table.filter((_, index) => index !== 12);
        return (
            <div>
                <ul>
              {filteredZones.map(({ name, color }, index) => (
                    <li key={index}>
                        <span style={{ color: color}} className='me-1'>■</span>
                        {name}
                    </li>
              ))}
                </ul>
            </div>
          );
    }

    // デフォルト表示
    return (
    <div>
        <Header />

        <div className="guide">
            <div className='sidebarStyle py-2'>
                {guide}
                <button type="button" className="btn btn-success half-circle py-0 mx-2" onClick={getAction}>{btnname}</button>
                <span className='text-danger'>{actioninfo}</span>
                <input type="hidden" id="action" value={action} ref={inputRef} />              
            </div>
        </div>
        <div className='map-container' ref={mapContainer} />

        <Dialog2 selectedValue={selectedValue} onDialog2Change={handleDialog2Change} />
        <Dialog3 apiParam={executeparam} apiPosts={executeresult} selectedValue={blockID} onDialog3Change={handleDialog3Change} />
        <Dialog4 apiParam={executeparam} apiPosts={executeresult} selectedValue={parcel} onDialog4Change={handleDialog4Change} />
        <Dialog5 apiPosts={executeresult} selectedValue={parcel} />
       
        <Sidebar apiPosts={executeresult} action={action} />

        {isLoading && <Loading />}

        {(action === 2 || action === 3) && <Draggable
            defaultPosition={{x: 0, y: 0}}
            cancel='p'>
            <div style={{ position: 'absolute', bottom: '80px', left: '10px' }}>
                <p className='mb-2'>凡例（ゾーン）</p>
                {hanrei}
            </div>
        </Draggable>
        }
    </div>
    );
};
