import React, { useEffect, useState, useRef, useMemo } from 'react';
import Draggable from 'react-draggable';

const Dialog3 = ({ onDialog3Change, ...props }) => {

  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [value5, setValue5] = useState('');

  const [blockid, setBlockid] = useState(props.selectedValue);
  const [zonetype, setZonetype] = useState('');

  const [total, setTotal] = useState('');
  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(true);

  const dialogRef = useRef(null);

  //console.log("d3 start=" + props.selectedValue);

  //console.log(props.apiPosts);

  const per = useMemo(() => {
    // perの初期化処理
    let calculatedPer = [];
    
    if (props.selectedValue > 0) {
      //console.log(props.apiPosts.parcel_new_station);
      //console.log(props.apiParam.prob_dict);

      var zone_type = props.apiPosts.parcel_new_station.find(item => item.block_id === props.selectedValue)?.zone_type;
      var prob_dict = props.apiParam.prob_dict[zone_type];
      //console.log("zone_type=" + zone_type);
      //console.log("prob_dict=" + prob_dict);

      setBlockid(props.selectedValue);
      setZonetype(zone_type);
      calculatedPer = [Number(prob_dict[0]*100).toFixed(2), Number(prob_dict[1]*100).toFixed(2), Number(prob_dict[2]*100).toFixed(2), Number(prob_dict[3]*100).toFixed(2), Number(prob_dict[4]*100).toFixed(2)];
    }
      return calculatedPer;
    }, [props.selectedValue, props.apiPosts, props.apiParam]);
  
    useEffect(() => {
      let caltotal = Number(Number(per[0]) + Number(per[1]) + Number(per[2]) + Number(per[3]) + Number(per[4]));
      //console.log("effiet1=" + per);
      setValue1(per[0]);
      setValue2(per[1]);
      setValue3(per[2]);
      setValue4(per[3]);
      setValue5(per[4]);
      setTotal(caltotal);
    }, [per]);

  const onInputValue1Changed = (e: ChangeEvent<HTMLInputElement>) => {
    setValue1(e.target.value);
    let caltotal = Number(e.target.value) + Number(value2) + Number(value3) + Number(value4) + Number(value5);
    setTotal(caltotal);
    inputCheck(caltotal);
  }
    const oninputValue2Changed = (e: ChangeEvent<HTMLInputElement>) => {
      setValue2(e.target.value);
      let caltotal = Number(value1) + Number(e.target.value) + Number(value3) + Number(value4) + Number(value5);
      setTotal(caltotal);
      inputCheck(caltotal);
    }
    const oninputValue3Changed = (e: ChangeEvent<HTMLInputElement>) => {
      setValue3(e.target.value);
      let caltotal = Number(value1) + Number(value2) + Number(e.target.value) + Number(value4) + Number(value5);
      setTotal(caltotal);
      inputCheck(caltotal);
    }
    const oninputValue4Changed = (e: ChangeEvent<HTMLInputElement>) => {
      setValue4(e.target.value);
      let caltotal = Number(value1) + Number(value2) + Number(value3) + Number(e.target.value) + Number(value5);
      //console.log("caltotal=" + caltotal);
      setTotal(caltotal);
      inputCheck(caltotal);
    }
    const oninputValue5Changed = (e: ChangeEvent<HTMLInputElement>) => {
      setValue5(e.target.value);
      let caltotal = Number(value1) + Number(value2) + Number(value3) + Number(value4) + Number(e.target.value);
      setTotal(caltotal);
      inputCheck(caltotal);
      //console.log("value5");

    }

  function inputCheck(caltotal){
    if(caltotal===100){
      setDisabled(false);
      setErrorText("");
    }else{
      setDisabled(true);
      let errormsg = (
        <p className='text-danger p-1'><small>合計が100%になるように入力してください。</small></p>
      );
      setErrorText(errormsg);
    }
  }
 
  // 選択ボタンクリック時
  const handleCalDialog = () => {
    //console.log("cal=" + selected);
    onDialog3Change(blockid, zonetype, value1, value2, value3, value4, value5);
    dialogRef.current.close();
    //dialogRef.current.showModal();
  };

  // キャンセルボタンクリック時
  const handleCloseDialog = () => {
    dialogRef.current.close();
  };

  return (
    <Draggable defaultPosition={{x: 0, y: 0}}
      cancel='p'>
      <dialog className='map-dialog p-0 border-info border-1' id='map-dialog3' ref={dialogRef}>
      {/*console.log("d3=render=" + total)*/}
        <h6 className='bg-info text-white p-2 mb-1'>土地利用の割合を入力してください</h6>
        <div className="px-2 mb-2">
        <table className="table-border table table-bordered">
            <thead>
                <tr>
                    <th>タイプ</th>
                    <th>割合<small>（合計が100%になるように）</small></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='pe-2'>1. 自然地</td>
                    <td><input type="text" maxLength="6" size="5" value={value1} onChange={onInputValue1Changed}></input> %</td>
                </tr>
                <tr>
                    <td>2. 住宅地</td>
                    <td><input type="text" maxLength="6" size="5" value={value2} onChange={oninputValue2Changed}></input> %</td>
                </tr>
                <tr>
                    <td>3. 商業地</td>
                    <td><input type="text" maxLength="6" size="5" value={value3} onChange={oninputValue3Changed}></input> %</td>
                </tr>
                <tr>
                    <td>4. 工業地</td>
                    <td><input type="text" maxLength="6" size="5" value={value4} onChange={oninputValue4Changed}></input> %</td>
                </tr>
                <tr>
                    <td>5. 公共地</td>
                    <td><input type="text" maxLength="6" size="5" value={value5} onChange={oninputValue5Changed}></input> %</td>
                </tr>
                <tr>
                    <td className='text-end pe-2'>合計</td>
                    <td>{total}%</td>
                </tr>
            </tbody>
        </table>
        {errorText}
        </div>
        <div className='text-center mb-2'>
          <button className="px-3 me-2" id="openButton" disabled={disabled} onClick={handleCalDialog}>計算</button>
          <button className="" id="closeButton" onClick={handleCloseDialog} >キャンセル</button>
        </div>
      </dialog>
      </Draggable>
    );
};

export default Dialog3;