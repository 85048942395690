// const ROAD_MAP_HASH  = "road_map";

const ROAD_BUILDING_ID  = "road_building_id";
const ROAD_LAND_USE_ID  = "road_land_use_id";

// const ROAD_BUILDING_AFTER_MASK_ID  = "road_building_after_mask_id";
// const ROAD_BUILDING_AFTER_REMAIN_ID  = "road_building_after_remain_id";
//
// const ROAD_LAND_USE_AFTER_MASK_ID  = "road_land_use_after_mask_id";
// const ROAD_LAND_USE_AFTER_REMAIN_ID  = "road_land_use_after_remain_id";

const ROAD_LINE_ID = "read_line_id";

const DRM_ID = "drm_id";
const CITY_ID = "city_id";

const ROAD_3D_BUILDING_SRC = "shizuoka_zmap_tatemono";
const ROAD_3D_BUILDING_ID = "shizuoka-building";

const map_draw_styles = [
    // ACTIVE (being drawn)
    // line stroke
    {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#D20C0C",
            // "line-dasharray": [0.2, 2],
            "line-width": 5
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.1
        }
    },
    // polygon mid points
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fbb03b'
        }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        "id": "gl-draw-polygon-stroke-active",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
        }
    },
    // vertex point halos
    {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 7,
            "circle-color": "#FFF"
        }
    },
    // vertex points
    {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 6,
            "circle-color": "#D20C0C",
        }
    },

];

// デフォルト道路
const default_road_line = {
    "type": "FeatureCollection",
    "name": "new_road_OD",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
        { "type": "Feature", "properties": { "id": 1, "name": "1", "x": 138.918067, "y": 35.195267 }, "geometry": { "type": "Point", "coordinates": [ 138.918066957938123, 35.195266792934056 ] } },
        { "type": "Feature", "properties": { "id": 2, "name": "2", "x": 138.91669, "y": 35.188621 }, "geometry": { "type": "Point", "coordinates": [ 138.916689582291838, 35.188620542772448 ] } },
        { "type": "Feature", "properties": { "id": 3, "name": "3", "x": 138.916954, "y": 35.187836 }, "geometry": { "type": "Point", "coordinates": [ 138.916954206061092, 35.187836074418406 ] } }
    ]
}

export {
    map_draw_styles,
    ROAD_BUILDING_ID,
    ROAD_LAND_USE_ID,
    ROAD_LINE_ID,
    DRM_ID,
    CITY_ID,
    ROAD_3D_BUILDING_SRC,
    ROAD_3D_BUILDING_ID,
    default_road_line
};
