//import React, { useEffect, useState } from 'react';
import axios from "axios";

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.baseURL = 'http://localhost:3000/';

const headers = {'X-Token': 'mycityplan_secret_token'}

const FetchResults = async (building_filename, land_use_filename) => {
    try {
        const base_api_url = process.env.REACT_APP_BASE_API_URL;

        // urls
        const urls = [
            base_api_url + "api/v1/road/execute/result/building?filename=" + building_filename,
            base_api_url + "api/v1/road/execute/result/land_use?filename=" + land_use_filename
        ];

        // リクエストだけ一括で投げる
        const promise_building = axios.get(urls[0], { headers });
        const promise_land_use = axios.get(urls[1], { headers });

        // Promise.all([])とawaitを併用して、終了を待つ
        const response = await Promise.all([promise_building, promise_land_use]);

        /*
        // promise_building
        console.log("promise_building = " + response[0].status);
        console.log(JSON.stringify(response[0].data));

        // promise_land_use
        console.log("promise_land_use = " + response[1].status);
        console.log(JSON.stringify(response[1].data));
        */

        if (response[0].status !== 200 || response[1].status !== 200) {
            alert("データがうまく取得できませんでした. code0=" + response[0].status + ", code1="+ response[1].status);
            return null;
        }
        return response;

    } catch (error) {
        // Handle the error
        alert("データがうまく取得できませんでした. error=" + error);
        return null;
    } finally {
        //console.log('done');
    }
};
  
export default FetchResults;
