//import React, { useEffect, useState } from 'react';
import axios from "axios";

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.baseURL = 'http://localhost:3000/';

const headers = {'X-Token': 'mycityplan_secret_token'}

const Fetchresults = async (params) => {
    try {
        const base_api_url = process.env.REACT_APP_BASE_API_URL;
        const url = base_api_url + "api/v1/station/execute"
        const response = await axios.post(url, params, { headers });
    } catch (error) {
        // Handle the error
        alert("データがうまく取得できませんでした" + error);
    } finally {
        //console.log('取得完了！execute');
    }
};
  
export default Fetchresults;