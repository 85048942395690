import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export default function DialogResultInfo(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{""}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <b>建物移転補償費：</b><br/>
                    道路新設に伴う建物の移動にかかる補償費用の合計額。<br/><br/>

                    <b>土地買取費：</b><br/>
                    道路新設に伴う私有地を取得する（用地取得面積）ための総費用です。<br/><br/>

                    <b>道路建設費：</b><br/>
                    新道路を建設するための総費用で、その面積は新道路の面積と同じである。<br/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
}
