import './Sidebar.css';

// import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import Button from '@mui/material/Button';
// import {useState} from "react";
import parse from 'html-react-parser';

const message_string = [
    /*
    "土地・建物を右クリックで詳細情報が表示されます。<br/><br/>" +
    "地図右側のツールバーを使って、新しい計画道路として線を引きます。<br/><br/>" +
    "左クリックでキーポイントを決定し、ダブルクリックで描き終わります。<br/><br/>" +
    "赤丸●は市計画中の新平松深良線の予定始点と終点です。（参考用）<br/><br/>" +
    "線を引き終えたら、「予定道路生成」ボタンを押してください。<br/><br/>",
    */

     "デフォルトの赤線は市で計画中の新平松深良線です。（参考用）<br/><br/>" +
     "土地・建物を<span style=\"color:red;\"><b>右クリック</b></span>すると詳細情報が表示されます。<br/><br/>" +
     "デフォルトの計画道路の線を使うか、それを削除して新たに計画道路の線を描くかのどちらかを選択してください。<br/><br/>" +
     "削除する場合は、<span style=\"color:red;\"><b>左クリック</b></span>で赤い線を選択し、地図右側のコントロールアイコンのゴミ箱アイコンで線を削除します。<br/>" +
     "赤丸●が表示されている道路が編集対象の道路です。<br/><br/>" +
     "新しい計画道路の線を引く場合は、コントロールアイコンの描画のアイコンを選択し、<span style=\"color:red;\"><b>左クリック</b></span>で点を決定していき、" +
     "<span style=\"color:red;\"><b>ダブルクリック</b></span>で描き終わります。<br/>引ける計画道路は1本のみです。<br/><br/>" +
     "線を引き終えたら「予定道路生成」ボタンを押して、道路幅を設定します。(今は都市計画道路という1種類の道路しかありません）。",

    "土地・建物を右クリックで詳細情報が表示されます。<br/><br/>" +
    "建物状態は、建物移転と部分買取があります。<br/><br/>" +
    "「建物移転」は、建物全体を移動させることです。元の建物や土地の50％以上が新しい道路区域に覆われている場合に起こります。<br/><br/>" +
    "「部分買取」は、元の建物や土地の一部が買取されることです。50％以下が新しい道路で覆われているに起こります。<br/><br/>" +
    "補助額は、基本的な建物・土地面積をもとに概算しています。",

];

export default function Sidebar(props) {
    const { action, onOpen } = props;

    function handleOnClick() {
        onOpen();
    }
    function LinkVolume() {
        if (action === 1) {
            return (
                <div id='sidebar_road'>
                    {parse(message_string[action])}
                    {parse("<br /><br />「表示」ボタンをクリックすると、交通量シミュレーション結果が表示されます。各道路区分をクリックすると、時間当たり通行人数推定が表示されます。<br /><br />")}
                    <Button variant="contained" onClick={() => handleOnClick()}>表示</Button>
                </div>
            );
        } else {
            return (
                <div id='sidebar_road'>
                    {parse(message_string[action])}
                </div>
            );
        }
    }

    return (
        <div>
            <LinkVolume />
        </div>
    );
}
