import loading from "../loading2.gif";
import React from "react";

export default function Loading() {
    return (
        <div>
            <div id="loading" className='loading'>
                <div className="text-center my-5 py-4">
                    <p><img src={loading} /></p>
                    ただいま処理中です・・・<br />しばらくお待ちください。</div>
            </div>
        </div>
    );
};
