import Base from './Base';

import {ROAD_3D_BUILDING_ID, ROAD_3D_BUILDING_SRC} from "./const";

export default class Building3D extends Base {
    constructor(name) {
        // 親クラスのコンストラクタの呼び出し
        super(name);
        this._map = null;
        this._src = ROAD_3D_BUILDING_SRC;
        this._id = ROAD_3D_BUILDING_ID;
    }

    get_id() {
        return this._id;
    }

    init(map) {
        this._map = map;

        map.addSource(this._src,{
            "type": "vector",
            "tiles": ["https://data.mlit-data.jp/shizuoka/{z}/{x}/{y}.pbf"],
            "minzoom": 0,
            "maxzoom": 18
        });
        map.addLayer(
            {
                "id": this._id,
                "type": "fill-extrusion",
                "source": this._src,
                "source-layer": "shizuoka_zmap_tatemono",
                "paint": {
                    "fill-extrusion-color": "#999999",
                    "fill-extrusion-opacity": 0.7,
                    "fill-extrusion-height": ["*",
                        ["case", ["has", "floor"],
                            ["case", ["==", ["get", "floor"], 0], 1, ["get", "floor"]],
                            1]
                        , 3.5]
                },
                "visible":true
            }
        );
    }
}