import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';

export default function DialogRoadSetting({ setDialogValue, ...props }) {
    const dialogRef = useRef(null);
    const [road_width, setRoadWidth] = useState(20.0);

    const oninputValueChanged = (e) => {
        let val = e.target.value;
        setRoadWidth(val);
    }

    // 選択ボタンクリック時
    const handleSelectDialog = () => {
        if (isNaN(road_width)) {
            alert("数値以外が入力されています");
            return;
        }
        let num = parseFloat(road_width);
        if (num <= 0) {
            alert("0以上の数値を入力してください");
            return;
        }
        setDialogValue(num);
        dialogRef.current.close();
    };

    // 閉じるボタンクリック時
    const handleCloseDialog = () => {
        dialogRef.current.close();
    };

    return (
        <Draggable defaultPosition={{x: 0, y: 0}}
          cancel='p'>
          <dialog className='map-dialog p-0 border-info border-1' id='map-dialog-road-setting' ref={dialogRef}>
            <div className="p-3">
            <h5>道路設定</h5>
            <table className="table-border table table-bordered mb-0 fs-6">
                <thead>
                    <tr>
                        <th>項目</th>
                        <th>値</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='pe-2'>道路幅 (m)</td>
                        <td><input type="text" maxLength="10" size="10" value={road_width} onChange={oninputValueChanged}></input></td>
                    </tr>
                    <tr>
                        <td className='pe-2'>道路種類</td>
                        <td>都市計画道路</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div className='text-center mb-2'>
                <button className="px-3 me-2"  onClick={handleSelectDialog} >設定</button>
                <button　onClick={handleCloseDialog} >キャンセル</button>
            </div>
          </dialog>
        </Draggable>
    );
};
