import React, { } from 'react';
import { HashRouter as Router, Routes, Route, } from "react-router-dom";
import Station from './Station/Station';
import Road from './Road/Road';
import './App.css';

export default function App() {
  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route path="/" element={<Station />} />
          <Route path="/road" element={<Road />} />
          <Route path="*" element={<div>404　Page Not Found</div>} />
        </Routes>
      </Router> 
    </div>
  );
}
