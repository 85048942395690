import numeral from "numeral"; // eslint-disable-line import/no-extraneous-dependencies
import Base from './Base';

import building_geojson_data from "./data/building.geojson";
import { ROAD_BUILDING_ID } from "./const";

export default class Building extends Base {
    constructor(name) {
        // 親クラスのコンストラクタの呼び出し
        super(name);
        this._id = ROAD_BUILDING_ID;
        this._parcel_data = null;
    }

    get_id() {
        return this._id;
    }

    init(map) {
        map.addSource(this._id, {
            type: "geojson",
            data: building_geojson_data
        });

        map.addLayer({
            id: this._id,
            type: "fill",
            source: this._id,
            paint: {
                'fill-outline-color': '#ff0000',
                'fill-color': '#ff7f50',
                'fill-opacity': 0.0
            }
        });
    }

    _get_calculation_info(bu_id) {
        let parcel_data = this._parcel_data;
        if (parcel_data) {
            let length = parcel_data.length;
            for (let ii=0; ii<length; ii++) {
                let row = parcel_data[ii];
                if ( parseInt(row["bu_id"]) == parseInt(bu_id)) {
                    return row;
                }
            }
        }
        return null;
    }

    set_parcel_data(parcel_data) {
        this._parcel_data = parcel_data;
    }

    reset_parcel_data() {
        this._parcel_data = null;
    }

    popup_dialog(map_obj, map, e) {
        const layer_id = this.get_id();
        const sv = 5;
        const box = [
            [e.point.x - sv, e.point.y - sv],
            [e.point.x + sv, e.point.y + sv]
        ];
        let features = map.queryRenderedFeatures(box, {layers: [layer_id]},);
        if (features.length <= 0) {
            return false
        }

        let properties = features[0]["properties"];
        // console.log("properties =" + JSON.stringify(properties));
        let bu_id = properties["bu_id"];  // 建物ID
        let parcel_building_data = this._get_calculation_info(bu_id);
        let description = '';

        if (parcel_building_data) {
            let use      = parcel_building_data["use"];  // 建物用途
            let floor     =  parseInt(parcel_building_data["floor"]);  // 階数
            let fa       = numeral(parcel_building_data["fa"]).format('0,0[.]00');  // 床面積
            let area     = numeral(parcel_building_data["area"]).format('0,0');  // 建ぺい面積
            let age      = parcel_building_data["age"];  // 築年数
            let bu_level = parcel_building_data["bu_level"];  // 建物の程度

            let structure = "";    // 建物構造
            if (parcel_building_data["structure"] == 1 ) {
                structure = "木造";
            }
            let move = "";         // 建物状態
            if (parcel_building_data["move"] >= 1.0) {
                move = "建物移転";
            } else if (parcel_building_data["move"] >= 0.5) {
                move = "部分買取";
            } else /* if (result_info["move"] == 0.0) */ {
                move = "保留";
            }
            let fa_after     = numeral(parcel_building_data["fa_after"]).format('0,0');    // 残り面積
            let compensation = numeral(parcel_building_data["compensation"]).format('0,0'); // 補償額

            description = '<table border="1">';
            description += '<tr class="popup-item"><th>項目</th><td>値</td></tr>';
            description += '<tr class="popup-item"><th>建物ID</th><td>'        + bu_id       + '</td></tr>';
            description += '<tr class="popup-item"><th>建物用途</th><td>'       + use          + '</td></tr>';
            description += '<tr class="popup-item"><th>階数</th><td>'          + floor        + '</td></tr>';
            description += '<tr class="popup-item"><th>建ぺい面積(㎡)</th><td>' + area         + '</td></tr>';
            description += '<tr class="popup-item"><th>床面積(㎡)</th><td>'    + fa           + '</td></tr>';
            description += '<tr class="popup-item"><th>築年数</th><td>'        + age          + '</td></tr>';
            description += '<tr class="popup-item"><th>建物の程度</th><td>'     + bu_level     + '</td></tr>';
            description += '<tr class="popup-item"><th>建物構造</th><td>'       + structure    + '</td></tr>';
            description += '<tr class="popup-item"><th>残り面積(㎡)</th><td>'   + fa_after     + '</td></tr>';
            description += '<tr class="popup-item"><th>建物状態</th><td>'       + move         + '</td></tr>';
            description += '<tr class="popup-item"><th>補償額(万円)</th><td>'   + compensation + '</td></tr>';
            description += '</table>';

        } else {
            description = '<table border="1">';
            description += '<tr class="popup-item"><th>項目</th><td>値</td></tr>';
            // description += '<tr class="popup-item"><th>建物ID</th><td>' + properties["id"] + '</td></tr>';
            description += '<tr class="popup-item"><th>建物ID</th><td>' + properties["bu_id"] + '</td></tr>';
            description += '<tr class="popup-item"><th>建物用途</th><td>' + properties["use"] + '</td></tr>';
            description += '<tr class="popup-item"><th>階数</th><td>' + parseInt(properties["floor"]) + '</td></tr>';
            // description += '<tr class="popup-item"><th>建ぺい面積(㎡)</th><td>' + numeral(properties["area"]).format('0,0') + '</td></tr>';
            description += '<tr class="popup-item"><th>床面積(㎡)</th><td>' + numeral(properties["fa"]).format('0,0[.]00') + '</td></tr>';
            description += '<tr class="popup-item"><th>築年数</th><td>' + properties["age"] + '</td></tr>';
            description += '<tr class="popup-item"><th>建物の程度</th><td>' + properties["bu_level"] + '</td></tr>';
            description += '<tr class="popup-item"><th>建物構造</th><td>' + "木造" + '</td></tr>';
            description += '</table>';

        }

        let coordinates = e.lngLat;
        map_obj.popup(coordinates, description);
        return true;
    }

    // draw_road_line_buffered_difference(map, road_line_buffered) {
    //     const layer_id = this.get_id();
    //     // diff map data
    //     var layer_features = map.querySourceFeatures(layer_id, {'sourceLayer': layer_id});
    //
    //     var geojson_difference = this.features_difference(ROAD_BUILDING_AFTER_REMAIN_ID, layer_features, road_line_buffered);
    //     var geojson_intersection = this.features_intersection(ROAD_BUILDING_AFTER_MASK_ID, layer_features, road_line_buffered);
    //
    //     if (geojson_difference && geojson_difference["features"].length > 0) {
    //         this.load_polygon_geojson(map, ROAD_BUILDING_AFTER_REMAIN_ID, geojson_difference, '#ff0000', '#ff69b4', 0.0);
    //     }
    //     if (geojson_intersection && geojson_intersection["features"].length > 0) {
    //         this.load_polygon_geojson(map, ROAD_BUILDING_AFTER_MASK_ID, geojson_intersection, '#ffebcd', '#fffaf0', 0.0);
    //     }
    // }
}