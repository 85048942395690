import React, { useEffect, useState, useRef, useMemo } from 'react';
import Draggable from 'react-draggable';

//const commonParameters = require('./common_parameters');

const Dialog4 = ({ onDialog4Change, ...props }) => {

  const [fid, setFid] = useState('');
  const [parcel_id, setParcel_id] = useState('');
  const [area, setArea] = useState('');
  const [block_id, setBlock_id] = useState('');
  const [zone_id, setZone_id] = useState('');
  const [bcr, setBcr] = useState('');
  const [far, setFar] = useState('');
  const [max_fa, setMax_fa] = useState('');
  const [max_bca, setMax_bca] = useState('');
  const [c_c, setC_c] = useState('');
  const [near_dist, setNera_dist] = useState('');
  const [road_width, setRoad_width] = useState('');
  const [length, setLength] = useState('');
  const [place, setPlace] = useState('');
  const [household_num, setHousehold_num] = useState('');
  const [household_type, setHousehold_type] = useState('');
  const [living_pop, setLiving_pop] = useState('');
  const [floor, setFloor] = useState('');
  const [height_build, setHeight_build] = useState('');

  const [blockid, setBlockid] = useState(props.selectedValue);
  const [lcode, setLcode] = useState('');

  const dialogRef = useRef(null);

  //console.log("d4 start=" + props.selectedValue);

  //console.log(props.selectedValue);

  const per = useMemo(() => {
    // perの初期化処理
    let calculatedPer = [];
    
    //console.log("id=" + props.selectedValue);

    if (props.selectedValue) {
      calculatedPer = props.apiPosts.parcel_new_station.find(item => item.parcel_id === props.selectedValue);
    }      
    //console.log(calculatedPer);
    return calculatedPer;
  }, [props.selectedValue]);
  
    useEffect(() => {
      //console.log("effiet1=" + per);
      if(per > ''){
        setFid(per["FID"]);
        setParcel_id(per["parcel_id"]);
        setArea(per["area"].toFixed(2));
        setBlock_id(per["block_id"]);
        setZone_id(per["zone_id"]);
        setBcr(Number(per["BCR"]*100));
        setFar(Number(per["FAR"]*100));
        setMax_fa(per["sim_FA"].toFixed(2));
        setMax_bca(per["sim_BCA"].toFixed(2));
        setC_c(per["C_C"].toFixed(1));
        setNera_dist(per["NEAR_DIST"].toFixed(2));
        setRoad_width(per["road_width"].toFixed(1));
        //setLength(per["length"].toFixed(1));
        //setPlace(per["place"]);
        setHousehold_num(per["house_num"]);
        setHousehold_type(per["household"]);
        setLiving_pop(per["pop"]);
        setFloor(per["floor"]);
        //setHeight_build(per["height_build"].toFixed(1));
      }
    }, [per]);

  const oninputValue1Changed = (e: ChangeEvent<HTMLInputElement>) => {
    setBcr(e.target.value);
  }
  const oninputValue2Changed = (e: ChangeEvent<HTMLInputElement>) => {
    setFar(e.target.value);
  }
 
  // 選択ボタンクリック時
  const handleCalDialog = () => {
    //console.log("cal=" + selected);
    onDialog4Change(block_id, fid, bcr, far);
    dialogRef.current.close();
    //dialogRef.current.showModal();
  };

  // キャンセルボタンクリック時
  const handleCloseDialog = () => {
    dialogRef.current.close();
  };

  return (
    <Draggable defaultPosition={{x: 0, y: 0}}
      cancel='p'>
      <dialog className='map-dialog p-0 border-info border-1' id='map-dialog4' ref={dialogRef}>
      {/*console.log("d4=render=")*/}
        <h6 className='bg-info text-white p-2 mb-1'>建ぺい率（%）と容積率（%）を入力してください</h6>
        <div className="px-2 mb-2">
        <table className="table-border table table-bordered fs-6">
            <thead>
                <tr>
                    <th>項目</th>
                    <th>値</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='pe-2'>区画ID</td>
                    <td>{parcel_id}</td>
                </tr>
                <tr>
                    <td className='pe-2'>面積(㎡)</td>
                    <td>{area}</td>
                </tr>
                <tr>
                    <td className='pe-2'>ブロックID</td>
                    <td>{block_id}</td>
                </tr>
                <tr>
                    <td className='pe-2'>ゾーンID</td>
                    <td>{zone_id}</td>
                </tr>
                <tr>
                    <td>建ぺい率(%)</td>
                    <td><input type="text" maxLength="6" size="5" value={bcr} onChange={oninputValue1Changed}></input> %</td>
                </tr>
                <tr>
                    <td>容積率(%)</td>
                    <td><input type="text" maxLength="6" size="5" value={far} onChange={oninputValue2Changed}></input> %</td>
                </tr>
                <tr>
                    <td className='pe-2'>建築面積(㎡)</td>
                    <td>{max_fa}</td>
                </tr>
                <tr>
                    <td className='pe-2'>床面積(㎡)</td>
                    <td>{max_bca}</td>
                </tr>
                <tr>
                    <td className='pe-2'>工事費(万円)</td>
                    <td>{c_c}</td>
                </tr>
                <tr>
                    <td className='pe-2'>最寄り駅までの距離(ｍ)</td>
                    <td>{near_dist}</td>
                </tr>
                <tr>
                    <td className='pe-2'>隣接の道路幅(ｍ)</td>
                    <td>{road_width}</td>
                </tr>
                <tr>
                    <td className='pe-2'>世帯タイプ</td>
                    <td>{household_type}</td>
                </tr>
                <tr>
                    <td className='pe-2'>世帯数</td>
                    <td>{household_num}</td>
                </tr>
                <tr>
                    <td className='pe-2'>人口</td>
                    <td>{living_pop}</td>
                </tr>
                <tr>
                    <td className='pe-2'>階数</td>
                    <td>{floor}</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div className='text-center mb-2'>
          <button className="px-3 me-2" id="openButton" onClick={handleCalDialog}>計算</button>
          <button className="" id="closeButton" onClick={handleCloseDialog} >キャンセル</button>
        </div>
      </dialog>
      </Draggable>
    );
};

export default Dialog4;