import Base from './Base';

import drm_geojson_data from "./data/drm_22220.geojson";
import { DRM_ID } from "./const";

const ROAD_SELECT_LINK_ID  = "road_select_link_id";

export default class Drm extends Base {
    constructor(name) {
        // 親クラスのコンストラクタの呼び出し
        super(name);
        this._id = DRM_ID;
    }

    get_id() {
        return this._id;
    }

    init(map) {
        map.addSource(this._id, {
            type: "geojson",
            data: drm_geojson_data
        });

        map.addLayer({
            id: this._id,
            type: "line",
            source: this._id,
            paint: {
                'line-color': '#00f',
                'line-width': 2
            }
        });
    }

    popup_dialog(map_obj, map, e) {
        let link_id = this.get_link_id(map_obj, map, e);
        if (link_id) {
            let description = '';
            description += '<table border="1">';
            description += '<tr class="popup-item"><th>項目</th><td>値</td></tr>';
            description += '<tr class="popup-item"><th>リンクID</th><td>' + link_id + '</td></tr>';
            description += '</table>';
            var coordinates = e.lngLat;
            map_obj.popup(coordinates, description);
            return true;
        }
        return false;
    }

    get_link_id(map_obj, map, e) {
        /*
        地理院地図Vector から地図データを取得する方法
        https://qiita.com/mg_kudo/items/48bdb6c6252708f7ee12
         */
        const layer_id = this.get_id();
        const sv = 5;
        const box = [
            [e.point.x - sv, e.point.y - sv],
            [e.point.x + sv, e.point.y + sv]
        ];
        const features = map.queryRenderedFeatures(box, {layers: [layer_id]},);
        if (features.length > 0) {
            let length = 1;
            for (let ii=0; ii<length; ii++) {
                let feature = features[ii];
                let properties = feature["properties"];
                // console.log("properties =" + JSON.stringify(properties));
                let link_id = properties["2424321"]
                return link_id;
            }
            return null;
        } else {
            return null;
        }
    }

    draw_select_link_id(map, link_id) {
        this.reset_select_link_id(map);
        map.addLayer({
            id: ROAD_SELECT_LINK_ID,
            type: "line",
            source: this._id,
            filter: ['==', "2424321", link_id],
            /*
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            }
            */
            paint: {
                'line-color': '#FF00FF',
                'line-width': 3,
                // 'line-dasharray': [1, 2],
            },
        })
    }
    reset_select_link_id(map) {
        if (map.getLayer(ROAD_SELECT_LINK_ID)) {
            map.removeLayer(ROAD_SELECT_LINK_ID);
            // map.removeSource(ROAD_SELECT_LINK_ID);
        }
    }

}