// import * as turf from "@turf/turf";

export default class Base {
    constructor(name) {
        this.name = name;
    }

    // features_difference(layer_name, layer_features, road_line) {
    //     var difference_features = [];
    //     for (var ii = 0; ii < road_line.features.length; ii++) {
    //         var road_line_geometry = road_line.features[ii].geometry;
    //
    //         for (var jj = 0; jj < layer_features.length; jj++) {
    //             var layer_geometry = layer_features[jj].geometry;
    //             var properties = layer_features[jj].properties;
    //             // 切り取ったポリゴン生成(difference)
    //             // (最初のポリゴンから2番目のポリゴンを切り取って、2つのポリゴンの違いを見つける)
    //             var difference = turf.difference(layer_geometry, road_line_geometry);
    //             if (difference !== null) {
    //                 properties["layer_name"] = layer_name;
    //                 properties["read_line_index"] = ii;
    //                 properties["layer_index"] = jj;
    //
    //                 difference["properties"] = properties;
    //                 difference_features.push(difference);
    //             }
    //         }
    //     }
    //
    //     if (difference_features.length > 0) {
    //         var difference_features_data = {
    //             "type": "FeatureCollection",
    //             "features": difference_features
    //         }
    //     }
    //     return difference_features_data;
    // }

    // features_intersection(layer_name, layer_features, road_line) {
    //     var intersection_features = [];
    //     for (var ii = 0; ii < road_line.features.length; ii++) {
    //         var road_line_geometry = road_line.features[ii].geometry;
    //
    //         for (var jj = 0; jj < layer_features.length; jj++) {
    //             var layer_geometry = layer_features[jj].geometry;
    //             var properties = layer_features[jj].properties;
    //             // 切り取ったポリゴン生成(intersect)
    //             // (1番目ポリゴンと2番目ポリゴンの重なった部分を切り取り)
    //             var intersection = turf.intersect(layer_geometry, road_line_geometry);
    //             if (intersection !== null) {
    //                 properties["layer_name"] = layer_name;
    //                 properties["read_line_index"] = ii;
    //                 properties["layer_index"] = jj;
    //
    //                 intersection["properties"] = properties;
    //                 intersection_features.push(intersection);
    //             }
    //         }
    //     }
    //
    //     if (intersection_features.length > 0) {
    //         var intersection_features_data = {
    //             "type": "FeatureCollection",
    //             "features": intersection_features
    //         }
    //     }
    //     return intersection_features_data;
    // }

    // load_polygon_geojson(map, layer_id, geojson_data, outline_color, color, opacity=0.0) {
    //     // ポリゴン表示
    //     map.addSource(layer_id, {
    //         type: "geojson",
    //         data: geojson_data
    //     });
    //     map.addLayer({
    //         id: layer_id,
    //         type: "fill",
    //         source: layer_id,
    //         layout: {},
    //         paint: {
    //             'fill-outline-color': outline_color,
    //             "fill-color": color,
    //             "fill-opacity": opacity,
    //         }
    //     });
    // }

}