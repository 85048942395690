export default function Header() {
    return (
        <div>
            <div className='header-nav container-fluid'>
                <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-2 mb-0'>
                    <div className='col-8 text-white text-start'>
                        <a href='/' className='fs-3 fw-bold d-flex align-items-center mb-1 text-white text-decoration-none'>MyCityPlan 市民参加型地域計画支援プラットフォーム</a>
                    </div>
                    <div className='col-4 text-white text-end'>カスタマイズ機能
                        <a className='btn btn-primary mx-2' href='/#/'>新駅</a>
                        <a className='btn bg-secondary text-white me-2' href='/#/road/'>新道路</a>
                    </div>
                </header>
            </div>
        </div>
    );
};
