//import React, { useEffect, useState } from 'react';
import axios from "axios";

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.baseURL = 'http://localhost:3000/';

const headers = {'X-Token': 'mycityplan_secret_token'}

const FetchExecute = async (road_line, road_width, road_line_buffered) => {
    try {
        // console.log("fetching-start");
        const params = {
            "city_id": 1038,       // city ID (裾野市固定)
            "city_code": "22220",  // 行政コード ID (裾野市固定)
            "road_info": 1,        // 道路種類ID (現在は固定)
            "road_info_text": "都市計画道路",    //  道路種類 (現在は固定)
            "road_line": road_line, // {"type": "FeatureCollection", "features": [{"id": "..", "type": "Feature", "properties": {}, "geometry": {"type": "LineString", "coordinates": [....]}}]}, # 道路線 GeoJSON(features)
            "road_width": road_width, // 道路幅(m):
            "road_line_buffered": road_line_buffered　// {"type": "FeatureCollection", "features": [{"type": "Feature", "properties": {}, "geometry": {"type": "Polygon", "coordinates": [...]}}]} # 道路線（幅付き） GeoJSON(features)
        }

        const base_api_url = process.env.REACT_APP_BASE_API_URL;
        const url = base_api_url + "api/v1/road/execute"
        const response = await axios.post(url, params, {headers});

        // console.log("response_data =" + JSON.stringify(response.data));
        // console.log("fetching-finish");
        return response.data;

    } catch (error) {
        // Handle the error
        alert("実行エラーが発生しました: " + error);
    } finally {
        //console.log('done');
    }
};

export default FetchExecute;