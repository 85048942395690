import numeral from "numeral"; // eslint-disable-line import/no-extraneous-dependencies
import Base from './Base';

import land_use_geojson_data from "./data/land_use.geojson";
import { ROAD_LAND_USE_ID } from "./const";

export default class LandUse extends Base {
    constructor(name) {
        // 親クラスのコンストラクタの呼び出し
        super(name);
        this._id = ROAD_LAND_USE_ID;
        this._parcel_data = null;
    }

    get_id() {
        return this._id;
    }

    init(map) {
        map.addSource(this._id, {
            type: "geojson",
            data: land_use_geojson_data
        });

        map.addLayer({
            id: this._id,
            type: "fill",
            source: this._id,
            paint: {
                'fill-outline-color': '#4169e1',
                'fill-color': '#4682b4',
                'fill-opacity': 0.0
            }
        });
    }
    _get_net_type_text(new_type) {
        /* 1:自然用地、2:住宅用地、3:商業用地、4:工業用地、5:公共用地 */
        new_type = parseInt(new_type);
        var new_type_text = "";
        if (new_type === 1) {
            new_type_text = "自然用地";
        } else if (new_type === 2) {
            new_type_text = "住宅用地";
        } else if (new_type === 3) {
            new_type_text = "商業用地";
        } else if (new_type === 4) {
            new_type_text = "工業用地";
        } else if (new_type === 4) {
            new_type_text = "公共用地";
        }
        return new_type_text;
    }

    _get_calculation_info(lu_id) {
        let parcel_data = this._parcel_data;
        if (parcel_data) {
            let length = parcel_data.length;
            for (let ii=0; ii<length; ii++) {
                let row = parcel_data[ii];
                if (parseInt(row["lot_id"]) == parseInt(lu_id)) {
                    return row;
                }
            }
        }
        return null;
    }

    set_parcel_data(parcel_data) {
        this._parcel_data = parcel_data;
    }

    reset_parcel_data() {
        this._parcel_data = null;
    }

    popup_dialog(map_obj, map, e) {
        const layer_id = this.get_id();
        const sv = 5;
        const box = [
            [e.point.x - sv, e.point.y - sv],
            [e.point.x + sv, e.point.y + sv]
        ];
        let features = map.queryRenderedFeatures(box, {layers: [layer_id]},);
        if (features.length <= 0) {
            return false
        }

        let properties = features[0]["properties"];
        // console.log("properties =" + JSON.stringify(properties));
        let lu_id = properties["lot_id"];  // 区画ID
        let parcel_land_use_data = this._get_calculation_info(lu_id);
        let description = '';

        if (parcel_land_use_data) {
            let new_type = this._get_net_type_text(parcel_land_use_data["new_type"]);   // 土地利用: 1:自然用地、2:住宅用地、3:商業用地、4:工業用地、5:公共用地
            let area = numeral(parcel_land_use_data["area_after_diff"]).format('0,0')  // 面積
            let pur_area = numeral(parcel_land_use_data["pur_area"]).format('0,0');    // 買取面積
            let purchase = numeral(parcel_land_use_data["purchase"]).format('0,0');    // 買取価格

            description = '<table border="1">';
            description += '<tr class="popup-item"><th>項目</th><td>値</td></tr>';
            description += '<tr class="popup-item"><th>区画ID</th><td>' + lu_id + '</td></tr>';
            description += '<tr class="popup-item"><th>土地利用</th><td>' + new_type + '</td></tr>';
            description += '<tr class="popup-item"><th>面積(㎡)</th><td>' + area + '</td></tr>';
            description += '<tr class="popup-item"><th>買取面積(㎡)</th><td>' + pur_area + '</td></tr>';
            description += '<tr class="popup-item"><th>買取価格(万円)</th><td>' + purchase + '</td></tr>';
            description += '</table>';

        } else {
            /* 1:自然用地、2:住宅用地、3:商業用地、4:工業用地、5:公共用地 */
            let new_type_text = this._get_net_type_text(properties["new_type"]);

            description = '<table border="1">';
            description += '<tr class="popup-item"><th>項目</th><td>値</td></tr>';
            description += '<tr class="popup-item"><th>区画ID</th><td>' + lu_id + '</td></tr>';
            description += '<tr class="popup-item"><th>土地利用</th><td>' + new_type_text + '</td></tr>';
            description += '<tr class="popup-item"><th>面積(㎡)</th><td>' + numeral(properties["area"]).format('0,0[.]00') + '</td></tr>';
            description += '</table>';
        }

        let coordinates = e.lngLat;
        map_obj.popup(coordinates, description);
        return true;

    }

    // draw_road_line_buffered_difference(map, road_line_buffered) {
    //     const layer_id = this.get_id();
    //     // diff map data
    //     var layer_features = map.querySourceFeatures(layer_id, {'sourceLayer': layer_id});
    //
    //     var geojson_difference = this.features_difference(ROAD_LAND_USE_AFTER_REMAIN_ID, layer_features, road_line_buffered);
    //     var geojson_intersection = this.features_intersection(ROAD_LAND_USE_AFTER_MASK_ID, layer_features, road_line_buffered);
    //
    //     if (geojson_difference && geojson_difference["features"].length > 0) {
    //         this.load_polygon_geojson(map, ROAD_LAND_USE_AFTER_REMAIN_ID, geojson_difference, '#808000', '#eee8aa', 0.0);
    //     }
    //     if (geojson_intersection && geojson_intersection["features"].length > 0) {
    //         this.load_polygon_geojson(map, ROAD_LAND_USE_AFTER_MASK_ID, geojson_intersection, '#b22222', '#bc8f8f', 0.0);
    //     }
    // }
}