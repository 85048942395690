import React, { useRef, useEffect, useState } from 'react';
import Draggable from "react-draggable";
import numeral from "numeral"; // eslint-disable-line import/no-extraneous-dependencies

import DialogRoadSetting from './DialogRoadSetting';
import DialogResultPopup from './DialogResultPopup';
import DialogResultInfo from './DialogResultInfo'

import FetchExecute from "./FetchExecute";
import FetchResults from "./FetchResults";

import Header from "../components/Header";
import Loading from "../components/Loading";
import Map from './Map';
import Sidebar from "./Sidebar";

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './Road.css';
// import CloseButton from '../components/CloseButton';

import LinkVolumePopup from '../components/LinkVolumePopup';
// import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

/*
Vanilla JS + CSS - Modal Popup (Dialog) Tutorial with Example
https://jasonwatmore.com/post/2023/01/04/vanilla-js-css-modal-popup-dialog-tutorial-with-example
*/

export default function Road() {
    const mapContainer = useRef(null);
    const _map_obj = useRef(null);

    const [guide, setGuide ] = useState(null);
    const [btnname, setBtnname ] = useState(null);
    // const [actioninfo, setActioninfo] = useState(null);
    // const [action, setAction ] = useState(0);
    const action_ref = useRef(0); // refオブジェクトを作成

    const [is_execute, setExecute] = useState(false);
    const [is_result, setIsResult] = useState(false);

    const [land_area, setLandArea ] = useState(0); // 用地取得面積(㎡)
    const [road_cost, setRoadCost ] = useState(0); // 道路建設費（万円）
    const [land_cost, setLandCost ] = useState(0); // 土地買取費（万円）
    const [building_compensation_cost, setBuildingCompensationCost ] = useState(0); // 建物移転補償費（万円）

    const [reault_popup_open, setDialogResultPopup] = useState(false);
    const [reault_info_open, setDialogResultInfo] = useState(false);

    const link_volume_popup_ref = useRef(false); // refオブジェクトを作成
    const [link_volume_popup, setLinkVolumePopup] = useState(false);

    // const [link_id, setLinkId] = useState([]);
    const [link_volume, setLinkVolume] = useState([]);
    //const [default_link_id, setDefaultLinkId] = useState([]);
    const [default_link_volume, setDefaultLinkVolume] = useState([]);

    const [sidebar_action, setSidebarAction] = React.useState(0);
    const [progress_open, setProgressOpen] = React.useState(false);


    // ----------------------------------------
    // リンクID選択
    // ----------------------------------------
    const handleLinkVolumeCallBack = (response_data) => {
        if (link_volume_popup_ref.current) {
            // console.log("link_id=" + id + ": values =" + JSON.stringify(values));
            if (response_data && response_data["values"]) {
                let ids = response_data["ids"];
                let values = response_data["values"];
                // setLinkId(ids);
                setLinkVolume(values);

                if (ids.length >= 1) {
                    const link_id = ids[0];
                    _map_obj.current.draw_link_id(link_id);
                }
            }
        }
    };

    // ----------------------------------------
    // 人流ダイアログ
    // ----------------------------------------
    const handleLinkVolumeDefaultCallBack = (response_data) => {
        // self._drm.draw_select_link_id(map, link_id);
        // console.log("link_id=" + id + ": values =" + JSON.stringify(values));
        if (response_data && response_data["values"]) {
            // let ids = response_data["ids"];
            let values = response_data["values"];
            // setDefaultLinkId(ids);
            setDefaultLinkVolume(values);
        }
    };

    // 人流ダイアログ： グラフ表示オープン
    const handleLinkVolumePopupOpen = () => {
        /* default 新道路 linkid: */
        // setLinkId(default_link_id)
        setLinkVolume(default_link_volume)
        _map_obj.current.road_remove_select_drm();

        /* ダイアログ表示 */
        link_volume_popup_ref.current = true;
        setLinkVolumePopup(true);
    };
    // 人流ダイアログ： グラフ表示クローズ
    const handleLinkVolumePopupClose = () => {
        /* ダイアログ非表示 */
       link_volume_popup_ref.current = false;
       setLinkVolumePopup(false);
    };

    // ----------------------------------------
    // 結果終了お知らせダイアログ
    // ----------------------------------------
    const handleDialogResultClose = (value) => {
        setDialogResultPopup(false);
    };

    // ----------------------------------------
    // 結果データ説明ダイアログ
    // ----------------------------------------
    const handleDialogResultInfoClose = (value) => {
        setDialogResultInfo(false);
    };

    // ----------------------------------------
    // Mapのベース（source)データロード
    // ----------------------------------------
    // Mapのベース（source)データロード： プログレス表示
    const handleProgressOpen = () => {
        setProgressOpen(true);
    };
    // Mapのベース（source)データロード： プログレス終了ハンドル
    const handleProgressClose = () => {
        if ({progress_open}) {
            setProgressOpen(false);
        }
    };

    // ----------------------------------------
    // 初期設定
    // ----------------------------------------
    useEffect(() => {
        if (_map_obj.current)
            return;

        handleProgressOpen();
        _map_obj.current = new Map("map");
        _map_obj.current.init(mapContainer.current, handleProgressClose, handleLinkVolumeCallBack);

        action_ref.current = 0;
        handleCount();

        // default link ids
        const link_ids = [36779422, 36779423, 36779424, 36779425, 36779426, 36779427]
        _map_obj.current.get_road_default_link_volume(link_ids, handleLinkVolumeDefaultCallBack);
    });

    // ----------------------------------------
    // アクション
    // ----------------------------------------
    const handleCount = () => {
       // console.log("action_ref.current =" + action_ref.current);
        if (action_ref.current == 0) {  // デフォルト
            setGuide("アクション１ 予定道路設定");
            setBtnname("予定道路生成");
            setSidebarAction(0);

            action_ref.current = 1;

        } else if (action_ref.current == 1) {
            click_road_info();  // 道路幅設定ダイアログ表示

        } else if (action_ref.current == 2) {
            setGuide("アクション2: 予定道路の計算結果 ");
            setBtnname("終了");
            setSidebarAction(1);

            action_ref.current = 3;

        } else if (action_ref.current == 3) {
            handleProgressOpen();               // プログレスマーク表示
            handleLinkVolumePopupClose();       // 人流ダイアログ消去
            handleDialogResultClose();          // 結果終了お知らせダイアログ消去

            _map_obj.current.road_remove_all_layerd();  // レイヤー削除 + デフォルト道路追加
            _map_obj.current.popup_reset();             // 右マウスPopup消去
            _map_obj.current.reset_parcel_data();       // 計算結果の建物と土地利用を消去
            _map_obj.current.disable_draw_control(false);   // 描画コントール有効

            setIsResult(false);

            action_ref.current = 0;
            handleCount();
        }
    }

    // ----------------------------------------
    // 道路幅設定ダイアログ表示
    // ----------------------------------------
    const click_road_info = () => {
        const road_line_count = _map_obj.current.get_road_line_count()
        if (road_line_count > 1) {
            alert('予定道路は1つのみです');
        } else if (road_line_count === 1) {
            // ダイアログを表示 道路設定(道路幅)
            const dialog_road_setting = document.getElementById('map-dialog-road-setting');
            if　(dialog_road_setting.open) {
                dialog_road_setting.close();
            }
            dialog_road_setting.showModal(); // Show the dialog
        } else {
            alert('予定道路がみつかりません');
        }
    }

    /* DialogRoadSetting()から呼ばれる */
    const handleDialogValue = (road_width) => {
        _map_obj.current.popup_reset();
        _map_obj.current.reset_parcel_data();
        _map_obj.current.disable_draw_control(true);

        const road_line_buffered = _map_obj.current.get_road_line_buffered(road_width);
        if (road_line_buffered !== null) {
            const road_line = _map_obj.current.get_road_line();
            _map_obj.current.popup_reset();

            // Buffered ライン描画
            _map_obj.current.draw_road_line_buffered(road_width);

            (async function () {
                setExecute(true);
                // API Station Result
                const response_data = await FetchExecute(road_line, road_width, road_line_buffered);
                if (!response_data) {
                    setExecute(false);
                    return;
                }
                // console.log("response_data =" + JSON.stringify(response_data));

                // calculation_done
                let result_building_filename = response_data["building"];   // 道路と重なった建物の追加データの計算結果のファイル名
                let result_land_use_filename = response_data["land_use"];   // 道路と重なった土地利用の追加データの計算結果のファイル名

                let result_land_area = response_data["cost"]["land_area"];  // 用地取得面積(㎡)
                let result_road_cost = response_data["cost"]["road_cost"];  // 道路建設費（万円）
                let result_land_cost = response_data["cost"]["land_cost"];  // 土地買取費（万円）
                let result_building_compensation_cost = response_data["cost"]["building_compensation_cost"]; // 建物移転補償費（万円）

                const result_parcel_data = await FetchResults(result_building_filename, result_land_use_filename);
                if (!result_parcel_data) {
                    setExecute(false);
                    return;
                }
                // 計算終了: 予定道路生成の計算が終了しました。計算結果は右マウスで、建物、土地、道路をクリックすると表示されます。
                setDialogResultPopup(true);

                // console.log("response_file_data =" + JSON.stringify(response_file_data));

                setLandArea(result_land_area); // 用地取得面積(㎡)
                setRoadCost(result_road_cost); // 道路建設費（万円）
                setLandCost(result_land_cost); // 土地買取費（万円）
                setBuildingCompensationCost(result_building_compensation_cost); // 建物移転補償費（万円）

                setIsResult(true);

                // 計算結果：区間データを設定
                _map_obj.current.set_parcel_data(result_parcel_data[0]["data"], result_parcel_data[1]["data"]);

                setExecute(false);

                action_ref.current = 2;
                handleCount();
            })();
        }
    };

    // function Progress() {
    //     return (
    //         <>
    //             <Backdrop
    //                 sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //                 open={progress_open}
    //             >
    //                 <CircularProgress color="inherit" />
    //             </Backdrop>
    //         </>
    //     );
    // };

    function LinkVolume() {
        return (
            <>
                {link_volume_popup && (
                    <div>
                        <Draggable
                            defaultPosition={{x: 0, y: 0}}
                            cancel='p1'>
                            <div style={{ position: 'fixed', bottom: '30px', right: '200px' }}>
                                <LinkVolumePopup link_volume={link_volume} />
                            </div>
                        </Draggable>
                    </div>
                )}
            </>
        );
    }


    const onclickResultInfo = () => {
        setDialogResultInfo(true);
    }

    function Result() {
        return (
            <div>
                <Draggable
                    defaultPosition={{x: 0, y: 0}}
                    cancel='p2'>
                    <div style={{ position: 'fixed', bottom: '30px', left: '10px', padding: '8px' }}>
                        <table className="table-border table-bordered result-base">
                            <tbody className="result-item">
                            <tr>
                                <th className="result-item_link" onClick={onclickResultInfo} >新道路面積/用地取得面積（㎡） </th>
                                <th className="result-item_link" onClick={onclickResultInfo} >道路建設費（万円）</th>
                                <th className="result-item_link" onClick={onclickResultInfo} >土地買取費（万円）</th>
                                <th className="result-item_link" onClick={onclickResultInfo} >建物移転補償費（万円）</th>
                            </tr>
                            <tr>
                                <td>{numeral(land_area).format('0,0[.]00')}</td>
                                <td>{numeral(road_cost).format('0,0[.]00')}</td>
                                <td>{numeral(land_cost).format('0,0[.]00')}</td>
                                <td>{numeral(building_compensation_cost).format('0,0[.]00')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Draggable>
            </div>
        );
    }

    return (
        <div>
            <Header />

            <div className="guide">
                <div className='sidebarStyle py-2'>
                    {guide}
                    <button type="button" className="btn btn-success half-circle py-0 mx-2" onClick={handleCount}>{btnname}</button>
                    {/* <span className='small text-danger'>{actioninfo}</span> */}
                    { progress_open && <CircularProgress color="inherit" size="1rem" /> }
                </div>
            </div>
            <Box sx={{ width: '100%', margin: '0px auto' }}>
                <Grid container spacing={0}>
                    <Grid item xs={9.8}>
                        <div ref={mapContainer} className="map-container-road" />
                        <DialogRoadSetting setDialogValue={handleDialogValue}/>
                        {is_execute && <Loading />}
                        {is_result && <div id="result" className='result'>
                            <Result />
                        </div>}
                    </Grid>
                    <Grid item xs={2.2}>
                        <Sidebar action={sidebar_action} onOpen={handleLinkVolumePopupOpen}/>
                    </Grid>
                </Grid>
            </Box>

            <LinkVolume />
            <DialogResultPopup open={reault_popup_open} onClose={handleDialogResultClose} />
            <DialogResultInfo open={reault_info_open} onClose={handleDialogResultInfoClose} />

            {/* <Progress /> */}
        </div>
    );
}
