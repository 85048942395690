import Base from './Base';

import city_geojson_data from "./data/city_22220.geojson";
import { CITY_ID } from "./const";

export default class City extends Base {
    constructor(name) {
        // 親クラスのコンストラクタの呼び出し
        super(name);
        this._map = null;
        this._id = CITY_ID;
    }

    get_id() {
        return this._id;
    }

    init(map) {
        this._map = map;

        map.addSource(this._id, {
            type: "geojson",
            data: city_geojson_data
        });

        map.addLayer({
            id: this._id,
            type: "line",
            source: this._id,
            paint: {
                'line-color': '#000',
                'line-width': 2
            }
        });
    }
}