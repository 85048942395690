import React, { } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
//import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';

const Sidebar = (props) => {
    //const [posts, setPosts] = useState([]);
    //const [isLoading, setIsLoading] = useState(true);

    //console.log("sidebar start =" + props.action);
    const posts = props.apiPosts;
    /*useEffect(() => {
      (async function () {
        const data = await Fetchposts();
        console.log(data);
  
        setPosts(data);
        setIsLoading(false);
      })();
    }, []);
    */
    //const currentLanduse = posts.new_station_cost_default?.current_landuse || [];
    let futureLanduse = props.action === 1 ? 
      posts.new_station_cost_default?.current_landuse || [] : 
      posts.new_station_cost_result?.future_landuse || [];

  let landuse0 = (Number(futureLanduse[0])).toFixed(2);
  let landuse1 = (Number(futureLanduse[1])).toFixed(2);
  let landuse2 = (Number(futureLanduse[2])).toFixed(2);
  let landuse3 = (Number(futureLanduse[3])).toFixed(2);
  let landuse4 = (Number(futureLanduse[4])).toFixed(2);
 
  // 円グラフ PieChart
  const data = [
    { name: '自然地', value: Number(landuse0) }, // Natural #74B669
    { name: '住宅地', value: Number(landuse1) }, // Residential #B69C69
    { name: '商業地', value: Number(landuse2) }, // Commercial #B66969
    { name: '工業地', value: Number(landuse3) }, // Industrial #697AB6
    { name: '公共他', value: Number(landuse4) }, // Public #9469B6
  ];
  const COLORS = ['#03AF7A', '#F6AA00', '#FF4B00', '#4DC4FF', '#005AFF'];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const radius = outerRadius + 20; // To put the label outside of the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
  };


  //parcel_new_station

  if(props.action <= 1) {
    const admincost = Number(posts.new_station_cost_default?.current_admin_cost).toFixed(2);
    const population = Number(posts.new_station_cost_default?.current_population);
    const taxcity = Number(posts.new_station_cost_default?.current_tax_city);
    const taxrealty = Number(posts.new_station_cost_default?.current_tax_realty);
    const traffic = Number(posts.new_station_cost_default?.current_traffic);
    const visitors = Number(posts.new_station_cost_default?.current_visitors);
  
    return (
    <div id='sidebar' className="p-3">
        {/*console.log("s=render")*/}

        <h6>土地利用割合</h6>
        <PieChart width={300} height={250}>
        <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`}  fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend fill="#333" />
          <Tooltip />
        </PieChart>

        <h6 className="mt-4 mb-3">コストと効果</h6>
        <dl className="row me-2">
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>行政コスト
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{admincost.toLocaleString()} 万円／人</span></dd>
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>都市計画税
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{taxcity.toLocaleString()} 万円</span></dd>
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>固定資産税
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{taxrealty.toLocaleString()} 万円</span></dd>
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>人口
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{population.toLocaleString()} 人</span></dd>
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>交通量
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{traffic.toLocaleString()} 人／日</span></dd>
          <dt className="col-7 mb-2 small">
            <i className="fa-sharp fa-regular fa-square-check me-2"></i>訪問者
          </dt>
          <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{visitors.toLocaleString()} 人／日</span></dd>
        </dl>
        <div className="bg-white p-3 mb-5">
              ここに表示されている初期値は、新駅計画前の現状です。
        </div>
      </div>
    );
    }else{

      const admincost = Number(posts.new_station_cost_result?.future_admin_cost.toFixed(2));
      const population = Number(posts.new_station_cost_result?.future_population);
      const taxcity = Number(posts.new_station_cost_result?.future_tax_city);
      const taxrealty = Number(posts.new_station_cost_result?.future_tax_realty);
      const traffic = Number(posts.new_station_cost_result?.future_traffic);
      const visitors = Number(posts.new_station_cost_result?.future_visitors);
      const public_cost = Number(posts.new_station_cost_result?.public_constrction_cost);
      const road_cost = Number(posts.new_station_cost_result?.road_constrction_cost);

      return (
        <div id='sidebar' className="p-3">
            {/*console.log("s=render")*/}
    
            <h6>土地利用割合</h6>
            <PieChart width={300} height={250}>
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend fill="#333" />
              <Tooltip />
            </PieChart>
    
            <h6 className="mt-4 mb-3">コストと効果</h6>
            <dl className="row me-2">
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>行政コスト
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{admincost.toLocaleString()} 万円／人</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>都市計画税
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{taxcity.toLocaleString()} 万円</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>固定資産税
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{taxrealty.toLocaleString()} 万円</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>人口
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{population.toLocaleString()} 人</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>交通量
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{traffic.toLocaleString()} 人／日</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>訪問者
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{visitors.toLocaleString()} 人／日</span></dd>
              
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>工事費
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{public_cost.toLocaleString()} 万円</span></dd>
              <dt className="col-7 mb-2 small">
                <i className="fa-sharp fa-regular fa-square-check me-2"></i>道路工事費
              </dt>
              <dd className="col-5 mb-2 bg-white p-0 small text-end"><span className="p-1">{road_cost.toLocaleString()} 万円</span></dd>
            </dl>
            {props.action === 2 && 
            <div className="bg-white p-3 mb-5">
              ゾーンの初期設定は、市民ワークショップで話し合ったまちづくりイメージです。<br />
              ここに表示されている値は、初期設定に基づいたシミュレーションの結果です。
            </div>
            }
            {props.action === 3 && 
            <div className="bg-white p-3 mb-5">
              各ゾーンに設定された土地利用割合の初期値は、市の現在の土地利用から推計されます。<br />
              ここに表示されている値は、ユーザ設定に基づいたシミュレーションの結果です。
            </div>
             }
            {props.action === 4 && 
            <div className="bg-white p-3 mb-5">
              各区画に設定された建ぺい率と容積率の初期値は、土地利用規制に基づいて設定されます。<br />
              ここに表示されている値は、ユーザ設定に基づいたシミュレーションの結果です。
            </div>
            }
            {props.action === 5 && 
            <div className="bg-white p-3 mb-5">
              ここに表示されている値は、ユーザ設定に基づいたシミュレーションの結果です。
            </div>
             }
          </div>
        );  
    }
  }

  export default Sidebar;