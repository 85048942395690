import React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


/*【React】react-chartjs-2 使い方まとめ
https://zenn.dev/saito2321/articles/85cfe362749f37
*/

// 時間あたり通行人数推定
export default function LinkVolumePopup(props) {
    const { link_volume } = props;

    const options = {
        responsive: true,
        plugins: {
            legend: false
        },
        animation: {
            duration: 500,
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 10,
                },
            },
        }
    };
    const data = {
        labels: [
                "00:00", "01:00", "02:00", "03:00", "04:00",
                "05:00", "06:00", "07:00", "08:00", "09:00",
                "10:00", "11:00", "12:00", "13:00", "14:00",
                "15:00", "16:00", "17:00", "18:00", "19:00",
                "20:00", "21:00", "22:00", "23:00"
        ],
        datasets: [
            {
                backgroundColor: 'rgb(9, 98, 155)',
                borderColor: 'rgb(9, 98, 155)',
                data: link_volume,
            },
        ],
    };

    return (
        <>
            <div>
                <h5>時間あたり通行人数推定</h5>
                <Bar data={data} options={options} />
            </div>
        </>
    );
}
