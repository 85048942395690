//import React, { useEffect, useState } from 'react';
import axios from "axios";
// import mapboxgl from "mapbox-gl";

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.baseURL = 'http://localhost:3000/';

const headers = {'X-Token': 'mycityplan_secret_token'}

const FetchLinkVolume = async (link_ids) => {
    try {
        const base_api_url = process.env.REACT_APP_BASE_API_URL;

        //カンマ区切り文字列に変換 -> URLエンコード
        let link_ids_uri = encodeURIComponent(link_ids.join(','));
        // const url = "https://plan-api.mycityforecast.net/api/v1/road/link_volume?link_ids=" + link_ids_uri;
        const url = base_api_url + "api/v1/road/link_volume?link_ids=" + link_ids_uri;

        const response = await axios.get(url, { headers });
        return response.data;

    } catch (error) {
        // Handle the error
        alert("実行エラーが発生しました: " + error);
    } finally {
        //console.log('done');
    }
};

export default FetchLinkVolume;