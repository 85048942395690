import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
// import { MapboxLegendControl, LegendOptions } from "@watergis/mapbox-gl-legend";
// import '@watergis/mapbox-gl-legend/css/styles.css';
import * as turf from "@turf/turf";
import numeral from "numeral";

import {
    map_draw_styles,
    ROAD_LINE_ID,
    default_road_line
} from "./const";

import Building3D from './Building3D';
import Building from './Building';
import LandUse from './LandUse';
import Drm from './Drm';
import City from './City';
import FetchLinkVolume from "./FetchLinkVolume";

// MapBox Access Token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default class Map {
    constructor(name) {
        this._name = name;
        this._map = null;
        this._map_draw = null;
        this._building3d = null;
        this._building = null;
        this._land_use = null;
        this._drm = null;
        this._city = null;
        this._popup = null;
        this._onlinkvolumecallback = null;
    }

    init(map_container, onLoadedCallback, onLinkVolumeCallback) {
        const map = new mapboxgl.Map({
            container: map_container,
            style: 'mapbox://styles/mapbox/streets-v11',
            bearing: -12,
            pitch: 50,
            // center: [138.9152124590662, 35.19399989531848],
            center: [138.9172124590662, 35.18899989531848],
            zoom: 15.5
        });
        this._map = map;
        this._onlinkvolumecallback = onLinkVolumeCallback;

        // Language: 'ja'
        map.addControl(new MapboxLanguage({defaultLanguage: 'ja'}));

        // scaleControl control
        const scaleControl = new mapboxgl.ScaleControl();
        map.addControl(scaleControl, 'top-left');

        // Zoom control
        const zoomControl = new mapboxgl.NavigationControl();
        map.addControl(zoomControl, 'top-left');

        // アトリビューションコントロールの作成
        const attributionControl = new mapboxgl.AttributionControl({
            compact: true // オプション: コンパクトな表示スタイルに設定
        });
        map.addControl(attributionControl, 'top-left');

        // Mapbox Draw
        const map_draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                line_string: true,
                trash: true
            },
            styles: map_draw_styles
        });
        this._map_draw = map_draw;
        //this.road_draw(true);


        // MapBox Draw Control
        map.addControl(map_draw, 'top-right');

        // Draw 更新イベント
        const updateDraw = (e) => {
            const data = map_draw.getAll();
            if (data.features.length > 0) {
                var length = turf.length(data, {units: 'kilometers'});
                var text = numeral(length).format('0.0[0000]');
                console.log(text);
            } else {
                if (e.type !== 'draw.delete')
                    alert('Click the map to draw a polygon.');
            }
        }
        map.on('draw.create', updateDraw);
        map.on('draw.delete', updateDraw);
        map.on('draw.update', updateDraw);

        const self = this;
        map.on('load', function () {
            // カーソルスタイルをデフォルトへ
            map.getCanvas().style.cursor = "";

            // デフォルト道路を追加
            self.add_default_road_line();

            // 3D建物追加
            self._building3d = new Building3D("building3d");
            self._building3d.init(map);

            // 行政界追加
            self._city = new City("city");
            self._city.init(map);

            // DRM追加
            self._drm = new Drm("drm");
            self._drm.init(map);

            // building data
            self._building = new Building("building");
            self._building.init(map);

            // land use data
            self._land_use = new LandUse("land_use");
            self._land_use.init(map);

            let building3d_id = self._building3d.get_id();
            let city_id = self._city.get_id();
            let drm_id = self._drm.get_id();
            let building_id = self._building.get_id();
            let land_use_id = self._land_use.get_id();
            map.moveLayer(building3d_id, city_id, drm_id, land_use_id, building_id);

            // 右メニューイベント
            map.on('contextmenu', function (e) {
                e.preventDefault();
                if (self._drm.popup_dialog(self, map, e)) {
                    return;
                } else if (self._building.popup_dialog(self, map, e)) {
                    return;
                } else if (self._land_use.popup_dialog(self, map, e)) {
                    return;
                }
            });

            map.on('click', function (e) {
                e.preventDefault();
                let link_id = self._drm.get_link_id(self, map, e);
                if (link_id) {
                    (async function () {
                        // API Station Result
                        const response_data = await FetchLinkVolume([link_id]);

                        //let id = response_data["id"];
                        //let values = response_data["values"];
                        // console.log("link_id=" + link_id + ": response_data (link volume) =" + JSON.stringify(response_data));
                        self._onlinkvolumecallback(response_data);
                    })();
                }
            });

            // const targets = {
            //    'building': ROAD_3D_BUILDING_ID
            // };
            // // showDefaultをfalseにするとデフォルトで非表示になります。
            // // add legend control without checkbox, and it will be hide as default
            // let options= {
            //     showDefault: true,
            //     showCheckbox: true
            //     //onlyRendered: true,
            //     //reverseOrder: true
            // };
            // map.addControl(new MapboxLegendControl(targets, options), "top-right");
        });

        map.on('idle', function () {
            // console.log('idle event occurred.');
            onLoadedCallback();
        })

        // function sourceCallback() {
        //     // assuming 'map' is defined globally, or you can use 'this'
        //     if (
        //         map.getSource(ROAD_BUILDING_ID) && map.isSourceLoaded(ROAD_BUILDING_ID) &&
        //         map.getSource(ROAD_LAND_USE_ID) && map.isSourceLoaded(ROAD_LAND_USE_ID) &&
        //         map.getSource(DRM_ID) && map.isSourceLoaded(DRM_ID)
        //     ) {
        //         console.log('source loaded!');
        //     }
        // }
        // map.on('sourcedata', sourceCallback);
    }

    // getElementsByClass = (searchClass) => {
    //     let classElements = new Array();
    //     let allElements = document.getElementsByTagName("*");
    //     let jj = 0;
    //     for (let ii = 0; ii < allElements.length; ii++) {
    //         if (allElements[ii].className == searchClass) {
    //             classElements[jj] = allElements[ii];
    //             jj ++;
    //         }
    //     }
    //     return classElements;
    // }

    // road_draw = (status) => {
    //     const result = this.getElementsByClass('mapbox-gl-draw_ctrl-draw-btn');
    //     const mapbox_gl_draw_ctrl_draw_btn = result[0]
    //     if (status) {
    //         mapbox_gl_draw_ctrl_draw_btn.prop("disabled", false);
    //         // $(".mapboxgl-ctrl").css('visibility','visible');
    //     } else {
    //         const map_draw = this._map_draw;
    //         map_draw.deleteAll();
    //         mapbox_gl_draw_ctrl_draw_btn.prop("disabled", true);
    //         // $(".mapboxgl-ctrl").css('visibility','hidden');
    //     }
    // }

    disable_draw_control = (status) => {
        /*
        <button class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line" title="LineString tool (l)"></button>
        <button class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash" title="Delete"></button>
        */
        // $(".mapbox-gl-draw_ctrl-draw-btn").prop("disabled", true);
        const child = document.getElementsByClassName("mapbox-gl-draw_ctrl-draw-btn");
        // const button0 = document.getElementsByClassName("mapbox-gl-draw_line");
        // const button1 = document.getElementsByClassName("mapbox-gl-draw_trash");
        if (status) {
            // ボタンにdisabledを設定する
            child[0].disabled = "disabled";
            child[1].disabled = "disabled";

            //const map_draw = this._map_draw;
            //map_draw.changeMode('static');

        } else {
            // ボタンのdisabled属性を取り除く
            child[0].disabled = null;
            child[1].disabled = null;
        }
    }

    road_remove_all_layerd() {
        this.road_remove_layerd(ROAD_LINE_ID);
        const map_draw = this._map_draw;
        if (map_draw) {
            map_draw.deleteAll();
            this.add_default_road_line();
        }
        this.road_remove_select_drm();
    }

    road_remove_select_drm() {
        const map = this._map;
        const drm = this._drm;
        if (map && drm) {
            drm.reset_select_link_id(map);
        }
    }

    road_remove_layerd(layer_id) {
        const map = this._map;
        if (map.getLayer(layer_id)) {
            map.removeLayer(layer_id);
            map.removeSource(layer_id);
        }
    }
    add_default_road_line() {
        const map_draw = this._map_draw;
        let coordinates = [];
        const features_length = default_road_line["features"].length;
        for (let ii=0; ii<features_length; ii++) {
            const point = default_road_line["features"][ii]["geometry"]["coordinates"]
            coordinates.push(point);
        }
        let feature = {
                "type": "Feature",
                "properties": {"name": "default_road_line"},
                "geometry": { "type": "LineString", "coordinates": coordinates}
        };
        let featureIds = map_draw.add(feature);
        map_draw.changeMode('simple_select', { featureIds: [featureIds[0]] })
    }

    get_road_line() {
        const map_draw = this._map_draw;
        // road_line is geojson format
        const road_line = map_draw !== null ? map_draw.getAll() : null;
        //console.log("road_line=" + JSON.stringify(road_line));
        return road_line;
    }
    get_road_line_count() {
        const road_line = this.get_road_line();
        if (road_line !== null) {
            const line_count = road_line.features.length;
            return line_count;
        } else {
            return 0;
        }
    }
    get_road_line_buffered(road_width) {
        const road_line = this.get_road_line();
        if (road_line !== null) {
            // buffered is geojson format
            let road_line_buffered = turf.buffer(road_line, road_width / 2, {units: 'meters'});  // road_widthは全幅
            //console.log("buffered=" + JSON.stringify(road_line_buffered));
            return road_line_buffered;
        } else {
            return null;
        }
    }

    draw_road_line_buffered(road_width) {
        const road_line_buffered = this.get_road_line_buffered(road_width);
        if (road_line_buffered !== null) {
            const map = this._map;

            // LINE　bufferd ポリゴン表示
            var outline_color = '#2f4f4f';
            var color = '#66cdaa';
            map.addSource(ROAD_LINE_ID, {
                type: "geojson",
                data: road_line_buffered
            });
            map.addLayer({
                id: ROAD_LINE_ID,
                type: "fill",
                source: ROAD_LINE_ID,
                paint: {
                    'fill-outline-color': outline_color,
                    "fill-color": color,
                    "fill-opacity": 0.5
                }
            });
        }
    }

    // draw_road_line_buffered_difference(road_width, parcel_data_building) {
    //     const road_line_buffered = this.get_road_line_buffered(road_width);
    //     if (road_line_buffered !== null) {
    //         const map = this._map;
    //         const building = this._building;
    //         const land_use = this._land_use;
    //
    //         if (building)
    //             building.draw_road_line_buffered_difference(map, road_line_buffered);
    //         if (land_use)
    //             land_use.draw_road_line_buffered_difference(map, road_line_buffered);
    //     }
    // }

    set_parcel_data(parcel_data_building, parcel_data_land_use) {
        const building = this._building;
        const land_use = this._land_use;
        if (building)
            building.set_parcel_data(parcel_data_building);
        if (land_use)
            land_use.set_parcel_data(parcel_data_land_use);
    }

    reset_parcel_data() {
        const building = this._building;
        const land_use = this._land_use;
        if (building)
            building.reset_parcel_data();
        if (land_use)
            land_use.reset_parcel_data();
    }

    popup(coordinates, description) {
        this.popup_reset();
        if (description) {
            const map = this._map;
            // let coordinates = e.lngLat;
            this._popup = new mapboxgl.Popup();
            this._popup.setLngLat(coordinates).setHTML(description).addTo(map);
        }
    }

    popup_reset() {
        if (this._popup) {
            this._popup.remove();
            this._popup = null;
        }
    }

    get_road_default_link_volume(link_ids, oncallbak) {
        // var self = this;
        if (link_ids) {
            (async function () {
                // API Station Result
                const response_data = await FetchLinkVolume(link_ids);

                /*
                let id = response_data["id"];
                let values = response_data["values"];
                */
                // console.log("link_id=" + link_id + ": response_data (link volume) =" + JSON.stringify(response_data));
                oncallbak(response_data);
            })();
        }
    }

    draw_link_id(link_id) {
        const map = this._map;
        this._drm.draw_select_link_id(map, link_id);
    }

}